import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../extra/Input";
import Button from "../../../extra/Button";
import { submitData } from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  studentAttendanceGet,
  attendanceSingleAdd,
} from "../../../../redux/slice/studentAttendanceSlice";
import { findStudentByFormNo } from "../../../../redux/slice/studentSlice";
import { setToast } from "../../../extra/toast";

const StudentAttendanceSingleAdd = ({ setChoiceYears, setChoiceMonth }) => {
  const { dialogueData, dialogueText } = useSelector((state) => state.dialogue);

  const [selectMonth, setSelectMonth] = useState("");
  const [selectYear, setSelectYear] = useState("");

  const { findStudent } = useSelector((state) => state.student);

  let studentName = findStudent.studentName || "";
  let student_id = findStudent._id || "";
  let formNo = findStudent.formNo || "";

  const option = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];
  const currentYear = new Date().getFullYear();
  const numberOfYears = 10;

  const allYears = Array.from({ length: numberOfYears }, (_, index) =>
    (currentYear + index).toString()
  );

  useEffect(() => {
    if (dialogueText) {
      setSelectYear(dialogueText?.year);
      setSelectMonth(dialogueText?.month);
    }
    dispatch(findStudentByFormNo(""));
  }, [dialogueText]);

  const handleSubmit = async (e) => {
    const addAttendance = submitData(e);
    let addAttendanceNew = {
      studentId: student_id,
      formNo,
      year: selectYear,
      month: selectMonth,
    };
    if (addAttendance || addAttendanceNew) {
      try {
        let response;
        // if (dialogueData) {
        //   const payload = { addInquiry, id: dialogueData._id };
        //   response = await dispatch(inquiryUpdate(payload)).unwrap();
        // } else {
        // }
        response = await dispatch(
          attendanceSingleAdd(addAttendanceNew)
        ).unwrap();
        console.log(response, "response.data.status");
        if (response.status) {
          if (response?.studentAttendance) {
            await dispatch(
              studentAttendanceGet(response?.studentAttendance?.year)
            );
            setChoiceMonth(response?.studentAttendance?.monthId);
            setChoiceYears(response?.studentAttendance?.year);
          }
          dispatch(closeDialog());
        } else {
          setToast(response.message);
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const handleFindStudent = (datas) => {
    dispatch(findStudentByFormNo(datas));
  };

  const dispatch = useDispatch();
  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-8 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-start formHead">
                <div className="col-8">
                  <h3 className="m20-bottom headTitle">Attendance Dialog</h3>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton "
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i class="ri-close-circle-line"></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="myForm">
                <div className="row align-items-start formBody p50-bottom">
                  <div className="col-lg-6 col-md-6 col-12">
                    <Input
                      type={`text`}
                      id={`formNo`}
                      name={`formNo`}
                      label={`Form No`}
                      errorMessage={`Enter Form No`}
                      onKeyUp={(e) => {
                        handleFindStudent(e.target.value);
                      }}
                      // disabled={(dialogueData || dialogueText) && true}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <Input
                      type={`text`}
                      id={`studentName`}
                      name={`studentName`}
                      label={`Student Name`}
                      errorMessage={`Enter Student Name`}
                      // disabled={true}
                      value={studentName}
                    />
                  </div>
                  <div className="col-12">
                    <div className="inputData m10-bottom">
                      <label>Months</label>
                    </div>
                    {option.map((res) => (
                      <Button
                        className={`${
                          selectMonth == res.value
                            ? "bg-second text-light border-second-2"
                            : "bg-third-light text-third border-third-2"
                        }   m10-right m5-bottom`}
                        text={res.name}
                        type={`button`}
                        onClick={() => {
                          !dialogueText?.month && setSelectMonth(res.value);
                        }}
                        disabled={
                          dialogueText?.month &&
                          selectMonth != res.value &&
                          true
                        }
                      />
                    ))}
                  </div>
                  <div className="col-12">
                    <div className="inputData m10-bottom">
                      <label>Years</label>
                    </div>
                    {allYears.map((res) => (
                      <Button
                        className={`${
                          selectYear == res
                            ? "bg-second text-light border-second-2"
                            : "bg-third-light text-third border-third-2"
                        }   m10-right m5-bottom`}
                        text={res}
                        type={`button`}
                        onClick={() => {
                          !dialogueText?.year && setSelectYear(res);
                        }}
                        disabled={
                          dialogueText?.year && selectYear != res && true
                        }
                      />
                    ))}
                  </div>
                </div>
                <div className="row m20-top formFooter">
                  <div className="col-12 text-end m0">
                    <Button
                      className={`bg-gray2 text-darkGray border-darkGray-2`}
                      text={`Cancel`}
                      type={`button`}
                      onClick={() => dispatch(closeDialog())}
                    />
                    <Button
                      type={`submit`}
                      className={`bg-second text-light border-second-light-2 m10-left`}
                      text={`Submit`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentAttendanceSingleAdd;
