import React, { useEffect, useState } from "react";
import Table from "../../../extra/Table";
import Title from "../../../extra/Title";
import Pagination from "../../../extra/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { Select } from "../../../extra/Input";
import { staffAllSalaryShow } from "../../../../redux/slice/staffSalarySlice";
import StaffSalaryDetails from "./StaffSalaryDetails";
import { PrintSalaryReceipt } from "./StaffSalary";
const StaffSalaryAll = ({ showSalary, setShowSalary }) => {
  const { admin } = useSelector((state) => state.auth);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const reGetData = localStorage.getItem("salaryShow");
  const mainData = showSalary || JSON.parse(reGetData);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [years, setYears] = useState("2024");
  const staffLeaveYear = ["2024", "2025"];

  const { salary } = useSelector((state) => state.staffSalary);

  // Pagination BOTH
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Server Get
  useEffect(() => {
    if (mainData?.salaryData?._id) {
      dispatch(
        staffAllSalaryShow({ staffId: mainData?.salaryData?._id, year: years })
      );
    }
  }, [mainData?.salaryData, years]);

  useEffect(() => {
    if (mainData?.salaryData?._id) {
      dispatch(
        staffAllSalaryShow({ staffId: mainData?.salaryData?._id, year: years })
      );
    }
  }, []);

  useEffect(() => {
    setData(salary);
  }, [salary]);

  // const handleDelete = (id) => {
  //   const data = warning();
  //   data
  //     .then((logouts) => {
  //       const yes = logouts.isConfirmed;
  //       console.log("yes", yes);
  //       if (yes) {
  //         dispatch(staffHolidayDelete(id));
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // Print Salary

  const [prints, setPrints] = useState(0);

  const [datas, setDatas] = useState({});

  const staffSalaryTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Staff Name",
      body: "staffName",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Staff Code",
      body: "staffCode",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Month",
      body: "month",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Year",
      body: "year",
      Cell: ({ row }) => (
        <span>
          {row.monthId}-{row.year}
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Total Work Hours",
      body: "totalWorkingHours",
      aText: "hr",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Total Hours",
      body: "average",
      Cell: ({ row }) => (
        <span>
          <div className="midBox">
            <span>{row?.totalStaffWorkingHours}</span>
            <p className={`${row.isAverage ? "text-success" : "text-danger"}`}>
              ({row.average}%)
            </p>
          </div>
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Leave Status",
      body: "paidLeave",
      Cell: ({ row }) => (
        <span>
          {row.paidLeave ? (
            <div className="midBox">
              <i class="ri-checkbox-circle-line text-success fs-20"></i>
              <p>({row.paidLeaveHours} hr)</p>
            </div>
          ) : (
            <div className="midBox">
              <i class="ri-close-circle-line text-second fs-20"></i>
              <p>(0)</p>
            </div>
          )}
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Per/HR Salary",
      body: "staffPerHourSalary",
      aText: "₹",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Salary",
      body: "payingSalary",
      aText: "₹",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Bounus/Penalty",
      body: "Bounus",
      Cell: ({ row }) => (
        <span
          className={
            row.bonus > 0
              ? `text-success`
              : row.penalty > 0
              ? `text-danger`
              : "0"
          }
        >
          {row.bonus > 0
            ? `+${row.bonus}₹`
            : row.penalty > 0
            ? `-${row.penalty}₹`
            : "0"}
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Total Salary",
      mainClass: `text-success fw-bold`,
      body: "totalPayingStaffSalary",
      aText: "₹",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Pay Salary",
      body: "isPay",
      Cell: ({ row }) => (
        <span>
          {row.isPay ? (
            <ActionButton
              className="bg-success-light text-success border-success-1  m5-right"
              onClick={() => {
                setPrints(1);
                setDatas(row);
              }}
              bIcon={`ri-printer-fill`}
              text={row?.salaryReceiptNo}
            />
          ) : (
            <i class="fa-solid fa-spinner text-blue fs-20"></i>
          )}
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-blue-light text-blue  border-blue-1  m5-right"
            onClick={() =>
              dispatch(openDialog({ type: "staffSalaryDetails", data: row }))
            }
            icon={`ri-information-line`}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
  ];

  return (
    <div className="dialoguePage">
      <div className="row justify-content-between align-items-center adminPageStart">
        <div className="col-sm-6 col-12">
          <Title
            name={`${mainData?.salaryData?.staffName}'s Salary`}
            icon={`ri-bank-card-line`}
          />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m10-right">
            <Searching
              type={`client`}
              data={salary}
              setData={setData}
              column={staffSalaryTable}
              className={`w-100`}
            />
          </div>
          <div>
            <Select
              option={staffLeaveYear}
              defaultValue={years}
              onChange={(e) => setYears(e)}
            />
          </div>
          <div>
            <Button
              className={`bg-second-light text-second border-second-2 m10-left`}
              icon={`ri-close-line`}
              onClick={() => {
                localStorage.removeItem("salaryShow");
                setShowSalary({ open: false });
              }}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={staffSalaryTable}
        PerPage={rowsPerPage}
        Page={page}
      />
      <Pagination
        type={"client"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={data?.length}
      />
      {dialogue && dialogueType === "staffSalaryDetails" && (
        <StaffSalaryDetails />
      )}
      {prints == 1 && (
        <PrintSalaryReceipt setPrints={setPrints} datas={datas} />
      )}
    </div>
  );
};

export default StaffSalaryAll;
