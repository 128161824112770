import { useDispatch, useSelector } from "react-redux";
import { closeDialog, openDialog } from "../../../../redux/slice/dialogSlice";
import Input from "../../../extra/Input";
import { useState } from "react";
import { ActionButton } from "../../../extra/Button";
import {
  staffAttendanceGet,
  staffAttendanceDelete,
  staffAttendanceUpdate,
  staffAttendanceToggle,
} from "../../../../redux/slice/staffAttendanceSlice";
import { warning } from "../../../util/Alert";
import ToggleSwitch from "../../../extra/ToggleSwitch";

const StaffAttendanceDetails = ({ access }) => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [editValue, setEditValue] = useState();
  const [editData, setEditData] = useState({
    date: "",
    punchIn: "",
    punchOut: "",
    breakIn: "",
    breakOut: "",
  });

  const handleGetStaff = async (id, dataId, userId, year) => {
    let getRes = await dispatch(
      staffAttendanceGet({ staffId: userId, year, command: false })
    );

    const foundObject = getRes?.payload?.staffAttendance?.find(
      (item) => item._id === dataId
    );

    dispatch(
      openDialog({
        type: "staffAttendanceDetails",
        data: { ...foundObject },
      })
    );
    setEditValue();
  };

  const handleEditTime = async (id, dataId, userId, year) => {
    let payload = { id, editData };
    let response = await dispatch(staffAttendanceUpdate(payload));

    if (response) {
      handleGetStaff(id, dataId, userId, year);
      setEditData({
        date: "",
        punchIn: "",
        punchOut: "",
        breakIn: "",
        breakOut: "",
      });
    }
  };

  const handleRepeatToggle = async (id, dataId, userId, year, val) => {
    let payload = { id, val };
    let response = await dispatch(staffAttendanceToggle(payload));
    debugger;
    if (response) {
      handleGetStaff(id, dataId, userId, year);
    }
  };

  const handleDeletePunch = async (id, dataId, userId, year) => {
    const data = warning("", "Remove Punch");
    let response;
    data
      .then(async (logouts) => {
        const yes = logouts.isConfirmed;
        if (yes) {
          response = await dispatch(staffAttendanceDelete(id));

          if (response.payload.status) {
            handleGetStaff(id, dataId, userId, year);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const dispatch = useDispatch();
  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="mainDiaogBox position-relative p35-x">
              <div
                className="closeButton  position-absolute"
                style={{ top: 10, right: 10, zIndex: "2" }}
                onClick={() => {
                  dispatch(closeDialog());
                }}
              >
                <i className="ri-close-circle-line"></i>
              </div>

              <div
                className="showHourTable overflow-auto"
                style={{ maxHeight: "90vh" }}
              >
                <table width={`100%`}>
                  <tr
                    className="position-sticky bg-light"
                    style={{ top: 0, zIndex: 1 }}
                  >
                    <th className="text-center">No</th>
                    <th className="text-center">Repeat</th>
                    <th className="text-center">Date ID</th>
                    <th className="text-center">Date</th>
                    <th className="text-center">Day</th>
                    <th className="text-center">Punch In Time</th>
                    <th className="text-center">Punch Out Time</th>
                    <th className="text-center bg-light">Total Punch Hours</th>
                    <th className="text-center">Break In Time</th>
                    <th className="text-center">Break Out Time</th>
                    <th className="text-center bg-light">Total Break Hours</th>
                    <th className="text-center">Total</th>
                  </tr>
                  {dialogueData?.punch?.length > 0 ? (
                    <>
                      {dialogueData?.punch?.map((data, i) => {
                        console.log("data", data);
                        return (
                          <tr className={``}>
                            <td className="text-center" width={70}>
                              {i + 1}
                            </td>
                            <td className="text-center" width={70}>
                              <ToggleSwitch
                                onClick={() =>
                                  handleRepeatToggle(
                                    data?._id,
                                    dialogueData._id,
                                    dialogueData.staffId,
                                    dialogueData?.year,
                                    data?.isRepeat
                                  )
                                }
                                value={data.isRepeat}
                              />
                            </td>
                            <td className="text-center" width={80}>
                              <Input
                                type={`number`}
                                value={data?.dayId}
                                disabled={true}
                              />
                            </td>
                            <td className="text-center">
                              {editValue >= 0 ? (
                                <Input
                                  type={`date`}
                                  defaultValue={data?.date}
                                  disabled={editValue !== i}
                                  onChangeVal={(e) => {
                                    setEditData((old) => {
                                      return {
                                        ...old,
                                        date: e.target.value,
                                      };
                                    });
                                  }}
                                  minValue={dialogueData?.thisMonthStart}
                                  maxValue={dialogueData?.thisMonthEnd}
                                />
                              ) : (
                                <Input
                                  type={`date`}
                                  value={data?.date}
                                  disabled={editValue !== i}
                                  minValue={dialogueData?.thisMonthStart}
                                  maxValue={dialogueData?.thisMonthEnd}
                                />
                              )}
                            </td>
                            <td className="text-center">
                              <Input
                                type={`text`}
                                value={data?.day}
                                disabled={true}
                                className={`text-center width-130`}
                                headClassName={`text-center`}
                              />
                            </td>
                            <td className="text-center">
                              {editValue >= 0 ? (
                                <Input
                                  type={`text`}
                                  defaultValue={data?.punchIn}
                                  className={`text-center width-90`}
                                  headClassName={`text-center`}
                                  disabled={editValue !== i}
                                  onChangeVal={(e) => {
                                    setEditData((old) => {
                                      return {
                                        ...old,
                                        punchIn: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              ) : (
                                <Input
                                  type={`text`}
                                  value={data?.punchIn}
                                  disabled={editValue !== i}
                                  className={`text-center width-90`}
                                  headClassName={`text-center`}
                                />
                              )}
                            </td>
                            <td className="text-center">
                              {editValue >= 0 ? (
                                <Input
                                  type={`text`}
                                  defaultValue={data?.punchOut}
                                  className={`text-center width-90`}
                                  headClassName={`text-center`}
                                  disabled={editValue !== i}
                                  onChangeVal={(e) => {
                                    setEditData((old) => {
                                      return {
                                        ...old,
                                        punchOut: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              ) : (
                                <Input
                                  type={`text`}
                                  value={data?.punchOut}
                                  disabled={editValue !== i}
                                  className={`text-center width-90`}
                                  headClassName={`text-center`}
                                />
                              )}
                            </td>
                            <td className="text-center bg-light">
                              <Input
                                type={`text`}
                                value={data?.todayHours}
                                disabled={true}
                                className={`text-center width-90`}
                                headClassName={`text-center`}
                              />
                            </td>
                            <td className="text-center">
                              {editValue >= 0 ? (
                                <Input
                                  type={`text`}
                                  defaultValue={data?.breakIn}
                                  className={`text-center width-90`}
                                  headClassName={`text-center`}
                                  disabled={editValue !== i}
                                  onChangeVal={(e) => {
                                    setEditData((old) => {
                                      return {
                                        ...old,
                                        breakIn: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              ) : (
                                <Input
                                  type={`text`}
                                  value={data?.breakIn}
                                  disabled={editValue !== i}
                                  className={`text-center width-90`}
                                  headClassName={`text-center`}
                                />
                              )}
                            </td>
                            <td className="text-center">
                              {editValue >= 0 ? (
                                <Input
                                  type={`text`}
                                  defaultValue={data?.breakOut}
                                  className={`text-center width-90`}
                                  headClassName={`text-center`}
                                  disabled={editValue !== i}
                                  onChangeVal={(e) => {
                                    setEditData((old) => {
                                      return {
                                        ...old,
                                        breakOut: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              ) : (
                                <Input
                                  type={`text`}
                                  value={data?.breakOut}
                                  disabled={editValue !== i}
                                  className={`text-center width-90`}
                                  headClassName={`text-center`}
                                />
                              )}
                            </td>
                            <td className="text-center bg-light">
                              <Input
                                type={`text`}
                                value={data?.todayBreakHours}
                                disabled={true}
                                className={`text-center width-90`}
                                headClassName={`text-center`}
                              />
                            </td>
                            <td className="text-center">
                              {access && (
                                <>
                                  {editValue == i ? (
                                    <ActionButton
                                      className="bg-success-light text-success  border-success-1  m5-right"
                                      icon={`ri-check-double-fill`}
                                      onClick={() =>
                                        handleEditTime(
                                          data?._id,
                                          dialogueData._id,
                                          dialogueData.staffId,
                                          dialogueData?.year
                                        )
                                      }
                                    />
                                  ) : (
                                    <ActionButton
                                      className="bg-gray2 text-darkGray  border-darkGray-1  m5-right"
                                      icon={`ri-pencil-fill`}
                                      onClick={() => setEditValue(i)}
                                    />
                                  )}
                                  {editValue == i ? (
                                    <ActionButton
                                      className="bg-danger-light text-danger  border-danger-1  m5-right"
                                      icon={`ri-close-fill`}
                                      onClick={() => setEditValue()}
                                    />
                                  ) : (
                                    <ActionButton
                                      className="bg-second-light text-second  border-second-1  m5-right"
                                      icon={`ri-delete-bin-fill`}
                                      onClick={() =>
                                        handleDeletePunch(
                                          data?._id,
                                          dialogueData._id,
                                          dialogueData.staffId,
                                          dialogueData?.year
                                        )
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-center bg-light">
                          {dialogueData?.totalHours} Hours
                        </td>
                        <td></td>
                        <td></td>
                        <td className="text-center bg-light">
                          {dialogueData?.totalBreakHours} Hours
                        </td>
                        <td className="text-center">
                          {dialogueData?.finalTotal} Hours
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan="25" className="text-center">
                        No Data Found !
                      </td>
                    </tr>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffAttendanceDetails;
