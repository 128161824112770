import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slice/authSlice";
import dialogSlice from "./slice/dialogSlice";
import staffSlice from "./slice/staffSlice";
import dashBoardSlice from "./slice/dashBoardSlice";
import staffLeaveSlice from "./slice/staffLeaveSlice";
import staffHolidaySlice from "./slice/staffHolidaySlice";
import staffSalarySlice from "./slice/staffSalarySlice";
import staffAttendanceSlice from "./slice/staffAttendanceSlice";
import inquirySlice from "./slice/inquirySlice";
import studentLeaveSlice from "./slice/studentLeaveSlice";
import studentAttendanceSlice from "./slice/studentAttendanceSlice";
import studentHolidaySlice from "./slice/studentHolidaySlice";
import batchSlice from "./slice/batchSlice";
import installmentSlice from "./slice/installmentSlice";
import courseSlice from "./slice/courseSlice";
import studentSlice from "./slice/studentSlice";

// Enable Redux DevTools Extension

const store = configureStore({
  reducer: {
    auth: authSlice,
    dialogue: dialogSlice,
    dashBoard: dashBoardSlice,
    // Student Slice
    inquiry: inquirySlice,
    student: studentSlice,
    studentLeave: studentLeaveSlice,
    studentAttendance: studentAttendanceSlice,
    studentHoliday: studentHolidaySlice,
    course: courseSlice,
    installment: installmentSlice,
    batch: batchSlice,
    // Staff Slice
    staff: staffSlice,
    staffLeave: staffLeaveSlice,
    staffAttendance: staffAttendanceSlice,
    staffHoliday: staffHolidaySlice,
    staffSalary: staffSalarySlice,
  },
});

export default store;
