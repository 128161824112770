import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, { Image, Select, Textarea } from "../../../extra/Input";
import Button from "../../../extra/Button";
import {
  editData,
  generateNum,
  objectToFormData,
  submitData,
} from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  studentAdd,
  studentUpdate,
} from "../../../../redux/slice/studentSlice";
import { batchGet } from "../../../../redux/slice/batchSlice";

const StudentAdd = () => {
  const dispatch = useDispatch();

  const { dialogueData } = useSelector((state) => state.dialogue);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
    }
  }, [dialogueData]);

  // select-option batch
  const [option, setOption] = useState([]);
  const { batch } = useSelector((state) => state.batch);
  useEffect(() => {
    dispatch(batchGet({ page: 0, limit: 10, search: "" }));
  }, []);
  useEffect(() => {
    setOption(batch);
  }, []);

  console.log("batch", batch);

  let opti = [];
  for (let i = 0; i < batch.length; i++) {
    opti.push({ name: batch[i].batchTime, value: batch[i]._id });
  }

  console.log("opti", opti);
  const handleSubmit = async (e) => {
    const addStudent = submitData(e);
    if (addStudent) {
      console.log("addStudent", addStudent);
      const formData = objectToFormData(addStudent);

      try {
        let response;
        if (dialogueData) {
          const payload = { formData, id: dialogueData._id };
          response = await dispatch(studentUpdate(payload)).unwrap();
        } else {
          response = await dispatch(studentAdd(formData)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-sm-10 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-start formHead">
                <div className="col-8">
                  <h3 className="text-second m20-bottom">Student Dialog</h3>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i class="ri-close-circle-line"></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="studentForm">
                <div className="formBody">
                  <div className="startForm">
                    <h5 className="text-second">Student Details :</h5>
                    <div className="row align-items-start formBody">
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                        <Input
                          type={`text`}
                          id={`studentName`}
                          name={`studentName`}
                          label={`Student Name`}
                          errorMessage={`Enter Student Name`}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                        <Input
                          type={`number`}
                          id={`formNo`}
                          name={`formNo`}
                          label={`Form No`}
                          errorMessage={`Enter Form No`}
                          validation={`^[a-zA-Z0-9]{6,6}$`}
                          validationError={`Invalid Form No`}
                          activeIcon={!dialogueData && `ri-information-line`}
                          activClick={() => generateNum(6, "formNo")}
                          readOnly
                        />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                        <Input
                          type={`date`}
                          id={`admissionDate`}
                          name={`admissionDate`}
                          label={`admission Date`}
                          errorMessage={`Enter Admission Date`}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-4  col-md-6 col-12">
                        <Select
                          id={`batchId`}
                          name={`batchId`}
                          label={`Batch Time`}
                          option={opti}
                          errorMessage={`Enter batch Time `}
                        />
                      </div>

                      <div className="col-lg-4 col-md-6 col-12">
                        <Input
                          type={`text`}
                          id={`course`}
                          name={`course`}
                          label={`Course`}
                          errorMessage={`Enter course`}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <Input
                          type={`number`}
                          id={`phoneNo`}
                          name={`phoneNo`}
                          label={`Mobile Number`}
                          errorMessage={`Enter Mobile Number`}
                          validation={`^[0-9]{10,10}$`}
                          validationError={`Invalid Mobile Number`}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <Input
                          type={`text`}
                          id={`email`}
                          name={`email`}
                          label={`Email`}
                          errorMessage={`Enter Email`}
                        />
                      </div>

                      <div className="col-lg-4 col-md-6 col-12">
                        <Input
                          type={`date`}
                          id={`dob`}
                          name={`dob`}
                          label={`Date of Birth`}
                          errorMessage={`Enter Date of Birth`}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        {/* <Input
                          type={`number`}
                          id={`totalFees`}
                          name={`totalFees`}
                          label={`Total Fee`}
                          errorMessage={`Enter total Fees`}
                        /> */}
                        <Input
                          type={`number`}
                          id={`totalFees`}
                          name={`totalFees`}
                          label={`Total Fee`}
                          errorMessage={`Enter Total Fee`}
                        />
                      </div>

                      <div className="col-lg-2 col-md-3 col-6 align-self-center mb-4">
                        <Input
                          type={`radio`}
                          id={`male`}
                          name={`gender`}
                          label={`Male`}
                          value={`male`}
                        />
                      </div>
                      <div className="col-lg-2 col-md-3 col-6 align-self-center mb-4">
                        <Input
                          type={`radio`}
                          id={`female`}
                          name={`gender`}
                          label={`Female`}
                          value={`female`}
                        />
                      </div>
                    </div>
                    <h5 className="text-second">Personal Details :</h5>
                    <div className="row align-items-start formBody">
                      <div className="col-lg-4 col-md-6 col-12">
                        <Image
                          type={`file`}
                          id={`studentPhoto`}
                          name={`studentPhoto`}
                          label={`StudentPhoto`}
                          errorMessage={`Enter Student Photo`}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <Input
                          type={`text`}
                          id={`adharCard`}
                          name={`adharCard`}
                          label={`Aadhar Card`}
                          errorMessage={`Enter Aadhar Card`}
                        />
                        <Input
                          type={`number`}
                          id={`whatsappNo`}
                          name={`whatsappNo`}
                          label={`Whatsapp No`}
                          errorMessage={`Enter Whatsapp No`}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <Textarea
                          id={`address`}
                          name={`address`}
                          label={`Address`}
                          row={5}
                          errorMessage={`Enter Address`}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <Input
                          type={`text`}
                          id={`educationLevel`}
                          name={`educationLevel`}
                          label={`Education Level`}
                          errorMessage={`Enter education Level`}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <Input
                          type={`text`}
                          id={`fatherName`}
                          name={`fatherName`}
                          label={`Father Name`}
                          errorMessage={`Enter Father Name`}
                        />
                      </div>
                      <div className="col-lg-4  col-md-6 col-12">
                        <Input
                          type={`number`}
                          id={`fatherNo`}
                          name={`fatherNo`}
                          label={`Father No`}
                          errorMessage={`Enter father No`}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row formFooter">
                  <div className="col-12 text-end m0">
                    <Button
                      className={`bg-gray2 text-darkGray border-darkGray-2`}
                      text={`Cancel`}
                      type={`button`}
                      onClick={() => dispatch(closeDialog())}
                    />
                    <Button
                      type={`submit`}
                      className={`bg-second text-light border-second-light-2 m10-left`}
                      text={`Submit`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentAdd;
