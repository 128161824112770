import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../extra/Input";
import Button, { ActionButton } from "../../../extra/Button";
import { editData, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  studentHolidayAdd,
  studentHolidayUpdate,
} from "../../../../redux/slice/studentHolidaySlice";

const StudentHolidayAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [isAttendanceChange, setIsAttendanceChange] = useState(true);
  const [isOldAttendanceChange, setIsOldAttendanceChange] = useState(true);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
      setIsAttendanceChange(dialogueData?.isAttendanceChange);
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addHoliday = submitData(e);
    if (addHoliday) {
      let payload = { ...addHoliday, isAttendanceChange };
      try {
        let response;
        if (dialogueData) {
          const editHoliday = {
            ...payload,
            studentHolidayId: dialogueData._id,
            isOldAttendanceChange,
          };
          response = await dispatch(studentHolidayUpdate(editHoliday)).unwrap();
        } else {
          response = await dispatch(studentHolidayAdd(payload)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response?.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const dispatch = useDispatch();
  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-8 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-start formHead">
                <div className="col-8">
                  <h3 className="m20-bottom headTitle">Holiday Dialog</h3>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton "
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i class="ri-close-circle-line"></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="holidaysForm">
                <div className="row align-items-start formBody p50-bottom">
                  <div className="col-lg-6 col-12">
                    <Input
                      type={`date`}
                      id={`holidayDate`}
                      name={`holidayDate`}
                      label={`Holiday Date`}
                      errorMessage={`Enter Holiday Date`}
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <Input
                      type={`text`}
                      id={`description`}
                      name={`description`}
                      label={`Description`}
                      errorMessage={`Enter Description`}
                    />
                  </div>

                  <div className="col-lg-6 col-12">
                    <div className="inputData">
                      <label>Apply New Date Holiday in Attendance ?</label>
                    </div>
                    <ActionButton
                      type={`button`}
                      className={`leaveType fs-14 ${
                        isAttendanceChange
                          ? "bg-success text-light"
                          : "bg-success-light text-success"
                      }  border-success-2 m10-right m5-top`}
                      text={`Yes`}
                      onClick={() => setIsAttendanceChange(true)}
                    />
                    <ActionButton
                      type={`button`}
                      className={`leaveType fs-14 ${
                        !isAttendanceChange
                          ? "bg-danger text-light"
                          : "bg-danger-light text-danger"
                      }  border-danger-2 m10-right m5-top`}
                      text={`No`}
                      onClick={() => setIsAttendanceChange(false)}
                    />
                  </div>
                  {dialogueData && (
                    <div className="col-lg-6 col-12">
                      <div className="inputData">
                        <label>Apply Old Date Holiday in Attendance ?</label>
                      </div>
                      <ActionButton
                        type={`button`}
                        className={`leaveType fs-14 ${
                          isAttendanceChange
                            ? "bg-success text-light"
                            : "bg-success-light text-success"
                        }  border-success-2 m10-right m5-top`}
                        text={`Yes`}
                        onClick={() => setIsOldAttendanceChange(true)}
                      />
                      <ActionButton
                        type={`button`}
                        className={`leaveType fs-14 ${
                          !isAttendanceChange
                            ? "bg-danger text-light"
                            : "bg-danger-light text-danger"
                        }  border-danger-2 m10-right m5-top`}
                        text={`No`}
                        onClick={() => setIsOldAttendanceChange(false)}
                      />
                    </div>
                  )}
                </div>
                <div className="row m20-top formFooter">
                  <div className="col-12 text-end m0">
                    <Button
                      className={`bg-gray2 text-darkGray border-darkGray-2`}
                      text={`Cancel`}
                      type={`button`}
                      onClick={() => dispatch(closeDialog())}
                    />
                    <Button
                      type={`submit`}
                      className={`bg-second text-light border-second-light-2 m10-left`}
                      text={`Submit`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentHolidayAdd;
