import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import Logo from "../../../../assets/images/logo.png";
import Input from "../../../extra/Input";
import moment from "moment";

const InquiryDetails = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  return (
    <div className="dialog">
      {/* <div className="w-100"> */}
      <div style={{ width: "40%" }}>
        <div className="row justify-content-center">
          <div className="col-sm-10 col-11">
            <div className="mainDiaogBox position-relative">
              <div className="row justify-content-between align-items-start formHead">
                <div className="col-8">
                  <h3 className="m20-bottom headTitle">Inquiry Info</h3>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton "
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i class="ri-close-circle-line"></i>
                  </div>
                </div>
              </div>

              <div className="mainProfileBoard mainAdminProfile">
                <div className="mainProfileBox">
                  <div className="row">
                    <div className=" col-12 m-auto">
                      <div className="row m40-sm-top m20-top">
                        <div className=" col-12">
                          <div className="adminDetails p20">
                            <div className="adminProfileTitle">
                              Personal Information
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Name</div>
                              <Input
                                type={`text`}
                                value={dialogueData?.name}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox ">
                              <div className="adminDetailTitle">Gender</div>
                              <Input
                                type={`text`}
                                value={dialogueData?.gender}
                                defaultValue={`--`}
                                disabled={true}
                                className={`text-capitalize`}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Course</div>
                              <Input
                                type={`text`}
                                value={dialogueData?.course}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Mobile No</div>
                              <Input
                                type={`text`}
                                value={dialogueData?.mobileNo}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">
                                Inquiry Date
                              </div>
                              <Input
                                type={`date`}
                                value={moment(dialogueData.createdAt).format(
                                  "YYYY-MM-DD"
                                )}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryDetails;
