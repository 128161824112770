import React, { useEffect, useRef, useState } from "react";
import Table from "../../../extra/Table";
import Title from "../../../extra/Title";
import Pagination from "../../../extra/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import Logo from "../../../../assets/images/logo.png";
import { Select } from "../../../extra/Input";
import { staffAllSalaryShow } from "../../../../redux/slice/staffSalarySlice";
import StaffSalaryDetails from "./StaffSalaryDetails";
import moment from "moment";
const StaffSalary = () => {
  const { admin } = useSelector((state) => state.auth);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [years, setYears] = useState("2024");
  const staffLeaveYear = ["2024", "2025"];

  const { salary } = useSelector((state) => state.staffSalary);

  // Pagination BOTH
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  useEffect(() => {
    if (admin?._id) {
      dispatch(staffAllSalaryShow({ staffId: admin._id, year: years }));
    }
  }, [years, admin?._id]);

  useEffect(() => {
    if (admin?._id) {
      dispatch(staffAllSalaryShow({ staffId: admin._id, year: years }));
    }
  }, []);

  useEffect(() => {
    setData(salary);
  }, [salary]);

  // const handleDelete = (id) => {
  //   const data = warning();
  //   data
  //     .then((logouts) => {
  //       const yes = logouts.isConfirmed;
  //       console.log("yes", yes);
  //       if (yes) {
  //         dispatch(staffHolidayDelete(id));
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // Print Salary

  const [prints, setPrints] = useState(0);

  const [datas, setDatas] = useState({});

  const staffSalaryTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Staff Name",
      body: "staffName",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Staff Code",
      body: "staffCode",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Month",
      body: "month",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Year",
      body: "year",
      Cell: ({ row }) => (
        <span>
          {row.monthId}-{row.year}
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Total Work Hours",
      body: "totalWorkingHours",
      aText: "hr",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Total Hours",
      body: "average",
      Cell: ({ row }) => (
        <span>
          <div className="midBox">
            <span>{row?.totalStaffWorkingHours}</span>
            <p className={`${row.isAverage ? "text-success" : "text-danger"}`}>
              ({row.average}%)
            </p>
          </div>
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Leave Status",
      body: "paidLeave",
      Cell: ({ row }) => (
        <span>
          {row.paidLeave ? (
            <div className="midBox">
              <i class="ri-checkbox-circle-line text-success fs-20"></i>
              <p>({row.paidLeaveHours} hr)</p>
            </div>
          ) : (
            <div className="midBox">
              <i class="ri-close-circle-line text-second fs-20"></i>
              <p>(0)</p>
            </div>
          )}
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Per/HR Salary",
      body: "staffPerHourSalary",
      aText: "₹",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Salary",
      body: "payingSalary",
      aText: "₹",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Bounus/Penalty",
      body: "Bounus",
      Cell: ({ row }) => (
        <span
          className={
            row.bonus > 0
              ? `text-success`
              : row.penalty > 0
              ? `text-danger`
              : "0"
          }
        >
          {row.bonus > 0
            ? `+${row.bonus}₹`
            : row.penalty > 0
            ? `-${row.penalty}₹`
            : "0"}
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Total Salary",
      mainClass: `text-success fw-bold`,
      body: "totalPayingStaffSalary",
      aText: "₹",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Pay Salary",
      body: "isPay",
      Cell: ({ row }) => (
        <span>
          {row?.isPay ? (
            <ActionButton
              className="bg-success-light text-success border-success-1  m5-right"
              onClick={() => {
                setPrints(1);
                setDatas(row);
              }}
              bIcon={`ri-printer-fill`}
              text={row?.salaryReceiptNo}
            />
          ) : (
            <i class="fa-solid fa-spinner text-blue fs-20"></i>
          )}
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-blue-light text-blue  border-blue-1  m5-right"
            onClick={() =>
              dispatch(openDialog({ type: "staffSalaryDetails", data: row }))
            }
            icon={`ri-information-line`}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center adminPageStart">
        <div className="col-sm-6 col-12">
          <Title name={"Staff Salary"} icon={`ri-wallet-2-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m10-right">
            <Searching
              type={`client`}
              data={salary}
              setData={setData}
              column={staffSalaryTable}
              className={`w-100`}
            />
          </div>
          <div>
            <Select
              option={staffLeaveYear}
              defaultValue={years}
              onChange={(e) => setYears(e)}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={staffSalaryTable}
        PerPage={rowsPerPage}
        Page={page}
      />
      <Pagination
        type={"client"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={data.length}
      />
      {dialogue && dialogueType === "staffSalaryDetails" && (
        <StaffSalaryDetails />
      )}
      {prints == 1 && (
        <PrintSalaryReceipt setPrints={setPrints} datas={datas} />
      )}
    </>
  );
};

export default StaffSalary;

export const PrintSalaryReceipt = ({ setPrints, datas }) => {
  console.log("datas", datas);
  const printableSalaryContentRef = useRef(null);

  const printDiv = (findClick) => {
    console.log("findClick", findClick);
    const styleElements = document.querySelectorAll("style");
    const linkElements = document.querySelectorAll("link");
    const content = printableSalaryContentRef.current.innerHTML;
    const newWindow = window.open("", "_blank");

    let newStyles = "";
    let newLinks = "";
    for (const style of styleElements) {
      newStyles += style.textContent;
    }
    for (const link of linkElements) {
      newLinks += link.outerHTML;
    }

    newWindow.document.write(`
    <html>
    <head>
    <link href="https://cdn.jsdelivr.net/npm/remixicon@3.4.0/fonts/remixicon.css" rel="stylesheet">
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet"
  integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM" crossorigin="anonymous">
  ${newLinks}
      <style>
        ${newStyles}
      </style\>
    </head\>
    <script src="https://kit.fontawesome.com/e9f488f24b.js" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-geWF76RCwLtnZ8qwWowPQNguL3RmwHVBC9FhGdlKrxdiJJigb/j/68SIy3Te4Bkz" crossorigin="anonymous"></script>
    <body><div class="${
      findClick == 2 && "doublePrint"
    }">${content}</div></body>
    </html>
`);
    newWindow.document.close();
    newWindow.print();
    newWindow.onafterprint = () => newWindow.close();
    setPrints(0);
  };

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-sm-10 col-11">
            <div
              class="receipt-container bg-light"
              ref={printableSalaryContentRef}
            >
              {[0, 1].map((_, i) => {
                return (
                  <div
                    className={`receipt-main ${i == 1 && "secondPrintMain"}`}
                  >
                    <div className={`startReceipt ${i == 1 && "secondPrint"}`}>
                      <div class="receipt-header">
                        <div className="logoContent betBox m10-bottom">
                          <div className="logoImg  width-140">
                            <img src={Logo} alt="logo" width={`100%`} />
                          </div>
                          <h5 align={`center`}>Salary Receipt</h5>
                          <div className="staffDetailsText  fs-14 fw-600">
                            <span> Date :</span>
                            <span className="text-darkGray">
                              {" "}
                              {moment(datas?.payingDate).format("DD-MM-YYYY")}
                            </span>
                          </div>
                        </div>
                        <div className="headerContet p10-y border-bottom-dark">
                          <div className="staffInformation betBox w-100 p20-right">
                            <div className="staffInformationDetails">
                              <div className="staffDetailsText">
                                <span> Employee Name :</span>
                                <span className="text-darkGray">
                                  {" "}
                                  {datas?.staffName}
                                </span>
                              </div>
                              <div className="staffDetailsText">
                                <span>Department :</span>
                                <span className="text-darkGray">
                                  {" "}
                                  {datas?.position}
                                </span>
                              </div>
                              <div className="staffDetailsText m0">
                                <span>Receipt No :</span>
                                <span className="text-darkGray">
                                  {" "}
                                  {datas?.salaryReceiptNo}
                                </span>
                              </div>
                            </div>
                            <div className="staffInformationDetails">
                              <div className="staffDetailsText">
                                <span>Pay Period :</span>
                                <span className="text-darkGray">
                                  {" "}
                                  {datas?.month}-{datas?.year}
                                </span>
                              </div>
                              <div className="staffDetailsText">
                                <span>Worked Days :</span>
                                <span className="text-darkGray">
                                  {" "}
                                  {datas?.totalWorkingDays} Days
                                </span>
                              </div>
                              <div className="staffDetailsText m0">
                                <span>GST NO :</span>
                                <span className="text-darkGray">
                                  {" "}
                                  24KSIPK8094N1ZR
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="receipt-body">
                        <div className="staffInfoBody">
                          <table className="table" border={1}>
                            <tr>
                              <th
                                className="bg-gray2 text-dark fw-600 text-center"
                                colSpan={2}
                                width={`40%`}
                              >
                                Staff Details
                              </th>
                              <th
                                className="bg-gray2 text-dark fw-600 text-center"
                                colSpan={2}
                                width={`60%`}
                              >
                                Staff Salary
                              </th>
                            </tr>
                            <tr>
                              <th>Employee Salary </th>
                              <td>{datas?.staffSalary}₹</td>
                              <th>Punch Time</th>
                              <td>{datas?.staffPunchHours}/hr</td>
                            </tr>
                            <tr>
                              <th>Employee Hours</th>
                              <td>{datas?.staffHours}/hr</td>
                              <th>Extra Holiday Hours</th>
                              <td> {datas?.manualHolidayHours}/hr</td>
                            </tr>
                            <tr>
                              <th className="bg-gray2 text-dark fw-600">
                                Working Hours
                              </th>
                              <td className="bg-gray2 text-dark fw-600">
                                {datas?.totalWorkingHours}/hr
                              </td>
                              <th>1 Paid Leave</th>
                              <td>{datas?.paidLeaveHours}/hr</td>
                            </tr>
                            <tr>
                              <th className="bg-gray2 text-dark fw-600">
                                Per Hours Salary
                              </th>
                              <td className="bg-gray2 text-dark fw-600">
                                {datas?.staffPerHourSalary} ₹
                              </td>
                              <th className="bg-gray2 text-dark fw-600">
                                Total Hours
                              </th>
                              <td className="bg-gray2 text-dark fw-600">
                                {datas?.totalStaffWorkingHours}/hr
                              </td>
                            </tr>
                            <tr>
                              <th>Extra Holiday</th>
                              <td>{datas?.manualHoliday} Days</td>
                              <th></th>
                              <td></td>
                            </tr>
                            <tr>
                              <th className="opacity-0">Blank</th>
                              <td></td>
                              <th></th>
                              <td></td>
                            </tr>
                            <tr>
                              <th></th>
                              <td></td>
                              <th className="bg-gray2 text-dark fw-600">
                                Total Salary
                              </th>
                              <td className="bg-gray2 text-dark fw-600">
                                {datas?.payingSalary}₹
                              </td>
                            </tr>
                            <tr>
                              <th></th>
                              <td></td>
                              <th>Bonus/Penalty</th>
                              <td>
                                {datas.bonus > 0
                                  ? `+${datas.bonus}₹`
                                  : datas.penalty > 0
                                  ? `-${datas.penalty}₹`
                                  : "0"}
                              </td>
                            </tr>
                            <tr>
                              <th></th>
                              <td></td>
                              <th className="bg-gray2 text-dark fw-600">
                                Net Amount
                              </th>
                              <td className="bg-gray2 text-dark fw-600">
                                {datas?.totalPayingStaffSalary}₹
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>

                      <div class="receipt-footer text-uppercase">
                        <div className="startFooter">
                          <div className="row">
                            <div className="col-8">
                              <div className="tages">
                                <span>RECEIVER SIGN</span>
                                <span className="signLine m40-top"></span>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="tages">
                                <span>ADMINISTRATOR SIGN</span>
                                <span className="signLine m40-top"></span>
                              </div>
                            </div>
                            <div className="col-12 m0 fs-12 fw-bold">
                              <span>NOTE: {datas.description}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="actionButtonForPrint m20-top midBox">
              <Button
                className={`bg-second text-light border-second-2`}
                bIcon={`ri-printer-line`}
                text={`Print`}
                type={`button`}
                onClick={() => printDiv(1)}
              />
              <Button
                className={`bg-second text-light border-second-2 m5-left`}
                bIcon={`ri-printer-line`}
                text={`Print(2)`}
                type={`button`}
                onClick={() => printDiv(2)}
              />
              <Button
                className={`bg-darkGray text-light border-darkGray-2 m5-left`}
                bIcon={`ri-close-line`}
                text={`Cancel`}
                type={`button`}
                onClick={() => setPrints(0)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
