import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  studentLeave: [],
  leaveTotal: 0,
  isLoading: false,
  isSkeleton: false,
};

export const studentLeaveGet = createAsyncThunk(
  "studentLeave/showStatusLeave",
  async (payload) => {
    return apiInstance.get(
      `studentLeave/showStatusLeave?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&leaveStatus=${payload.leaveStatus}`
    );
  }
);
export const studentLeaveAdd = createAsyncThunk(
  "studentLeave/createStudentLeave",
  async (payload) => {
    return apiInstance.post("studentLeave/createStudentLeave", payload);
  }
);

export const studentLeaveUpdate = createAsyncThunk(
  "studentLeave/updateStudentLeave",
  async (payload) => {
    return apiInstance.patch(
      `studentLeave/updateStudentLeave?studentLeaveId=${payload.studentLeaveId}`,
      payload.payload
    );
  }
);
export const studentLeaveStatusUpdate = createAsyncThunk(
  "studentLeave/updateStudentStatus",
  async (payload) => {
    return apiInstance.put(
      `studentLeave/updateStudentStatus?leaveId=${payload.leaveId}&leaveStatus=${payload.leaveStatus}`
    );
  }
);
export const studentLeaveDelete = createAsyncThunk(
  "studentLeave/deleteStudentLeave",
  async (payload) => {
    return apiInstance.delete(
      `studentLeave/deleteStudentLeave?studentLeaveId=${payload}`
    );
  }
);

const studentLeaveSlice = createSlice({
  name: "studentLeaveSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // studentLeaveGet
    builder.addCase(studentLeaveGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(studentLeaveGet.fulfilled, (state, action) => {
      state.studentLeave = action.payload.leavesAll;
      state.leaveTotal = action.payload.leaveTotal;
      state.isSkeleton = false;
    });
    builder.addCase(studentLeaveGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // studentLeaveUpdate
    builder.addCase(studentLeaveUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(studentLeaveUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const studentLeaveIdx = state?.studentLeave?.findIndex(
          (staffLeave) => staffLeave._id === action.payload.studentLeave._id
        );
        if (studentLeaveIdx !== -1) {
          state.studentLeave[studentLeaveIdx] = {
            ...state.studentLeave[studentLeaveIdx],
            ...action.payload.studentLeave,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(studentLeaveUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // studentLeaveStatusUpdate
    builder.addCase(studentLeaveStatusUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(studentLeaveStatusUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const studentLeaveIdx = state?.studentLeave?.findIndex(
          (staffLeave) => staffLeave._id === action.payload.leaveStatus._id
        );
        if (studentLeaveIdx !== -1) {
          state.studentLeave[studentLeaveIdx] = {
            ...state.studentLeave[studentLeaveIdx],
            ...action.payload.leaveStatus,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(studentLeaveStatusUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // studentLeaveDelete
    builder.addCase(studentLeaveDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(studentLeaveDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.studentLeave = state?.studentLeave?.filter(
          (studentLeave) => studentLeave._id !== action.meta.arg
        );
        state.leaveTotal -= 1;
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(studentLeaveDelete.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default studentLeaveSlice.reducer;
