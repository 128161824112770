import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import { DashBox } from "../../dashboard/Dashboard";
import { employeeDashData } from "../../../../redux/slice/dashBoardSlice";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import Title from "../../../extra/Title";

const StaffAttendanceChart = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const {
    pirChartDetails,
    lineChartDetails,
    thisMonthData,
    staffAllAttendance,
  } = useSelector((state) => state.dashBoard?.employeeDashboard);

  const currDate = new Date();

  const liveMonth = currDate.getMonth() + 1;
  const liveYears = currDate.getFullYear();

  // Pie Chart
  const pieChartDataAll = [
    ["Task", "Hours per Day"],
    ["Ruguler", pirChartDetails?.regularPunch],
    ["Late", pirChartDetails?.latePunch],
    ["Holiday", pirChartDetails?.holidayPunch],
    ["Other", pirChartDetails?.extraPunch], // CSS-style declaration
  ];

  const pieChartOptions = {
    title: `${thisMonthData?.month} - ${thisMonthData?.year} Activities`,
    pieHole: 0.4,
    is3D: false,
    colors: ["#198754", "#DC3545", "#0D6EFD", "#6D7080"],
    legend: {
      position: "bottom",
      textStyle: { fontSize: 14 },
    },
  };

  // Line Chart
  let allPunchData = [];
  for (let i = 0; i < lineChartDetails?.punch?.length; i++) {
    let arr = [
      `${lineChartDetails?.punch[i]?.dayId}`,
      lineChartDetails?.punch[i]?.punchInt,
      lineChartDetails?.punch[i]?.punchOutInt,
    ];

    allPunchData.push(arr);
  }

  useEffect(() => {
    if (dialogueData?.staffId) {
      dispatch(
        employeeDashData({
          staffId: dialogueData.staffId,
          monthId: dialogueData.monthId,
          year: dialogueData.year,
          command: false,
        })
      );
    }
  }, [dialogueData.staffId]);

  const dispatch = useDispatch();
  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xxl-6 col-xl-7 col-md-9 col-11">
            <div className="mainDiaogBox position-relative p35-x overflow-auto">
              <div
                className="closeButton  position-absolute"
                style={{ top: 10, right: 10, zIndex: "2" }}
                onClick={() => {
                  dispatch(closeDialog());
                }}
              >
                <i className="ri-close-circle-line"></i>
              </div>

              <div className="employeeSystem">
                <Title
                  name={`March Punch Data`}
                  className={`m10-bottom m20-top text-second`}
                />
                <div className="row">
                  <div className="col-12">
                    <div className="row employeeDash">
                      <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                        <DashBox
                          title={`Year`}
                          count={thisMonthData?.year}
                          className={`p10`}
                          classNameBody={`fs-22 text-darkGray`}
                          classNameHead={`fs-14`}
                          icon={`ri-calendar-event-line`}
                          color={`darkGray`}
                        />
                      </div>
                      <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                        <DashBox
                          title={`Month`}
                          count={thisMonthData?.month}
                          className={`p10`}
                          classNameBody={`fs-22 text-second`}
                          classNameHead={`fs-14`}
                          icon={`ri-calendar-todo-line`}
                          color={`second`}
                        />
                      </div>
                      <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                        <DashBox
                          title={`Punch Hours`}
                          count={thisMonthData?.totalHours || 0}
                          className={`p10`}
                          classNameBody={`fs-22 text-darkGray`}
                          classNameHead={`fs-14`}
                          icon={`ri-calendar-check-line`}
                          color={`darkGray`}
                        />
                      </div>
                      <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                        <DashBox
                          title={`Break Hours`}
                          count={thisMonthData?.breakHours || 0}
                          className={`p10`}
                          classNameBody={`fs-22 text-second`}
                          classNameHead={`fs-14`}
                          icon={`ri-cup-line`}
                          color={`second`}
                        />
                      </div>
                      <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                        <DashBox
                          title={`Total Hours`}
                          count={thisMonthData?.totalWorkingHours || 0}
                          className={`p10`}
                          classNameBody={`fs-22 text-darkGray`}
                          classNameHead={`fs-14`}
                          icon={`ri-hourglass-fill`}
                          color={`darkGray`}
                        />
                      </div>
                      <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                        <DashBox
                          title={`Holiday`}
                          count={thisMonthData?.holiday || 0}
                          className={`p10`}
                          classNameBody={`fs-22 text-second`}
                          classNameHead={`fs-14`}
                          icon={`ri-calendar-close-line`}
                          color={`second`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 m15-bottom">
                    <Chart
                      chartType="PieChart"
                      width="100%"
                      height="320px"
                      data={pieChartDataAll}
                      options={pieChartOptions}
                      className="pieChart"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffAttendanceChart;
