import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, { Textarea } from "../../../extra/Input";
import Button, { ActionButton } from "../../../extra/Button";
import { editData, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  findStaffById,
  staffLeaveCreate,
  staffLeaveGet,
  staffLeaveUpdate,
} from "../../../../redux/slice/staffLeaveSlice";
import { setToast } from "../../../extra/toast";

const StaffLeaveAdd = () => {
  const { dialogueData, dialogueText } = useSelector((state) => state.dialogue);
  const { findStaff } = useSelector((state) => state.staffLeave);
  const dispatch = useDispatch();
  const [isLeave, setIsLeave] = useState(true);
  const [isBreak, setIsBreak] = useState(false);
  let staffName = findStaff.staffName || "";
  let staff_id = findStaff._id || "";
  let staffCode = findStaff.staffCode || "";
  useEffect(() => {
    if (dialogueData || dialogueText) {
      editData(dialogueData || dialogueText);
      setIsBreak(dialogueData?.isBreak);
      setIsLeave(dialogueData?.isLeave);
      dispatch(
        findStaffById(dialogueData?.staffCode || dialogueText?.staffCode)
      );
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addLeave = submitData(e);
    if (addLeave) {
      let payload = {
        ...addLeave,
        staffId: staff_id,
        staffCode,
        isLeave: isLeave ? true : false,
        isHalfLeave: !isLeave ? true : false,
        isBreak: !isLeave ? isBreak : true,
      };
      if (!isLeave) {
        payload = { ...payload, leaveTo: payload.leaveFrom, isBreak };
      }
      console.log("payload", payload);
      try {
        let response;
        if (dialogueData) {
          response = await dispatch(
            staffLeaveUpdate({ payload, staffLeaveId: dialogueData._id })
          ).unwrap();
        } else {
          response = await dispatch(staffLeaveCreate(payload)).unwrap();
        }
        console.log("response", response);
        if (response.status) {
          const { staffId, year } =
            response.staffLeave[0] || response.staffLeave;
          dispatch(
            staffLeaveGet({
              staffId,
              year,
              command: false,
            })
          );
          dispatch(closeDialog());
        } else {
          setToast(response.message);
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  const handleFindStaff = (datas) => {
    dispatch(findStaffById(datas));
  };

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-xl-5 col-md-8 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-start formHead">
                <div className="col-8">
                  <h3 className="headTitle m20-bottom">Staff Leave Dialog</h3>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                      dispatch(findStaffById(""));
                    }}
                  >
                    <i class="ri-close-circle-line"></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="staffLeaveForm">
                <div className="row align-items-start formBody p40-bottom">
                  <div className="col-12">
                    <div className="showLeaveType">
                      {(!dialogueData || dialogueData.isLeave) && (
                        <ActionButton
                          type={`button`}
                          className={`leaveType fs-14 ${
                            isLeave
                              ? "bg-prime text-light"
                              : "bg-prime-light text-prime"
                          }  border-prime-2 m10-right`}
                          text={`Full Leave`}
                          onClick={() => {
                            setIsLeave(true);
                            setIsBreak(false);
                          }}
                        />
                      )}
                      {(!dialogueData || dialogueData.isHalfLeave) && (
                        <ActionButton
                          type={`button`}
                          className={`leaveType fs-14 ${
                            !isLeave
                              ? "bg-prime text-light"
                              : "bg-prime-light text-prime"
                          }  border-prime-2`}
                          text={`Half Leave`}
                          onClick={() => {
                            setIsLeave(false);
                            setIsBreak(false);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <Input
                      type={`text`}
                      id={`staffCode`}
                      name={`staffCode`}
                      label={`Staff Code`}
                      errorMessage={`Enter Staff Code`}
                      onKeyUp={(e) => {
                        handleFindStaff(e.target.value);
                      }}
                      disabled={(dialogueData || dialogueText) && true}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <Input
                      type={`text`}
                      id={`staffName`}
                      name={`staffName`}
                      label={`Staff Name`}
                      errorMessage={`Enter Staff Name`}
                      disabled={(dialogueData || dialogueText) && true}
                      value={staffName}
                    />
                  </div>
                  {isLeave && (!dialogueData || dialogueData.isLeave) && (
                    <>
                      <div className="col-lg-6 col-12">
                        <Input
                          type={`date`}
                          id={`leaveFrom`}
                          name={`leaveFrom`}
                          label={`Leave From`}
                          errorMessage={`Enter Leave From`}
                          minValue={
                            dialogueData?.thisMonthStart
                              ? dialogueData?.thisMonthStart
                              : false
                          }
                          maxValue={
                            dialogueData?.thisMonthEnd
                              ? dialogueData?.thisMonthEnd
                              : false
                          }
                        />
                      </div>
                      <div className="col-lg-6 col-12">
                        <Input
                          type={`date`}
                          id={`leaveTo`}
                          name={`leaveTo`}
                          label={`Leave To`}
                          errorMessage={`Enter Leave To`}
                          minValue={
                            dialogueData?.thisMonthStart
                              ? dialogueData?.thisMonthStart
                              : false
                          }
                          maxValue={
                            dialogueData?.thisMonthEnd
                              ? dialogueData?.thisMonthEnd
                              : false
                          }
                        />
                      </div>
                    </>
                  )}
                  {(!isLeave || dialogueData?.isHalfLeave) && (
                    <>
                      <div className="col-lg-6 col-12">
                        <Input
                          type={`date`}
                          id={`leaveFrom`}
                          name={`leaveFrom`}
                          label={`Leave Date`}
                          errorMessage={`Enter Leave Date`}
                          minValue={
                            dialogueData?.thisMonthStart
                              ? dialogueData?.thisMonthStart
                              : false
                          }
                          maxValue={
                            dialogueData?.thisMonthEnd
                              ? dialogueData?.thisMonthEnd
                              : false
                          }
                        />
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="inputData">
                          <label>Your Half Day Between Break?</label>
                        </div>
                        <ActionButton
                          type={`button`}
                          className={`leaveType fs-14 ${
                            isBreak
                              ? "bg-success text-light"
                              : "bg-success-light text-success"
                          }  border-success-2 m10-right m5-top`}
                          text={`Yes`}
                          onClick={() => setIsBreak(true)}
                        />
                        <ActionButton
                          type={`button`}
                          className={`leaveType fs-14 ${
                            !isBreak
                              ? "bg-danger text-light"
                              : "bg-danger-light text-danger"
                          }  border-danger-2 m10-right m5-top`}
                          text={`No`}
                          onClick={() => setIsBreak(false)}
                        />
                      </div>
                      <div className="col-lg-6 col-12">
                        <Input
                          type={`time`}
                          id={`timeFrom`}
                          name={`timeFrom`}
                          label={`Time From`}
                          errorMessage={`Enter Time From`}
                        />
                      </div>
                      <div className="col-lg-6 col-12">
                        <Input
                          type={`time`}
                          id={`timeTo`}
                          name={`timeTo`}
                          label={`Time To`}
                          errorMessage={`Enter Time To`}
                        />
                      </div>
                    </>
                  )}
                  <div className="ol-12">
                    <Textarea
                      row={5}
                      id={`description`}
                      name={`description`}
                      label={`Description`}
                      errorMessage={`Enter Description`}
                    />
                  </div>
                </div>
                <div className="row m20-top formFooter">
                  <div className="col-12 text-end m0">
                    <Button
                      className={`bg-gray text-light`}
                      text={`Cancel`}
                      type={`button`}
                      onClick={() => dispatch(closeDialog())}
                    />
                    <Button
                      type={`submit`}
                      className={`bg-second text-light m10-left`}
                      text={`Submit`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffLeaveAdd;
