import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  attendance: [],
  attendanceYear: [],
  attendanceTotal: 0,
  isLoading: false,
  isSkeleton: false,
};

export const staffAttendanceGet = createAsyncThunk(
  "staffAttendance/showStaffData",
  async (payload) => {
    return apiInstance.get(
      `staffAttendance/showStaffData?staffId=${payload?.staffId}&year=${payload?.year}`
    );
  }
);
export const staffAttendanceUpdate = createAsyncThunk(
  "staffAttendance/editPunchTime",
  async (payload) => {
    return apiInstance.patch(
      `staffAttendance/editPunchTime?staffAttendanceId=${payload.id}`,
      payload.editData
    );
  }
);
export const staffAttendanceDelete = createAsyncThunk(
  "staffAttendance/deletePunch",
  async (payload) => {
    return apiInstance.delete(
      `staffAttendance/deletePunch?staffAttendanceId=${payload}`
    );
  }
);

export const staffSalaryRequest = createAsyncThunk(
  "staffSalary/createStaffSalary",
  async (payload) => {
    return apiInstance.post(`staffSalary/createStaffSalary`, payload);
  }
);
export const staffAttendanceToggle = createAsyncThunk(
  "staffAttendance/repeatPunchToggleSwitch",
  async (payload) => {
    debugger
    return apiInstance.put(
      `staffAttendance/repeatPunchToggleSwitch?punchId=${payload.id}&val=${payload.val}`
    );
  }
);

const staffAttendanceSlice = createSlice({
  name: "staffAttendanceSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // staffAttendanceGet
    builder.addCase(staffAttendanceGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(staffAttendanceGet.fulfilled, (state, action) => {
      state.attendance = action.payload.staffAttendance;
      state.attendanceYear = action.payload.staffAttendanceYear;
      state.isSkeleton = false;
    });
    builder.addCase(staffAttendanceGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // staffSalaryRequest
    builder.addCase(staffSalaryRequest.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffSalaryRequest.fulfilled, (state, action) => {
      if (action.payload.status) {
        const staffAttendanceIdx = state.attendance.findIndex(
          (staffAttendance) =>
            staffAttendance._id === action.payload.staffSalaryRequest.punchId
        );
        if (staffAttendanceIdx !== -1) {
          state.attendance[staffAttendanceIdx] = {
            ...state.attendance[staffAttendanceIdx],
            payingRequest: true,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffSalaryRequest.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default staffAttendanceSlice.reducer;
