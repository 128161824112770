import { useDispatch, useSelector } from "react-redux";
import { closeDialog, openDialog } from "../../../../redux/slice/dialogSlice";
import Logo from "../../../../assets/images/logo.png";
import { baseURL } from "../../../util/config";
import { useEffect, useState } from "react";
import { ActionButton } from "../../../extra/Button";
import { staffSalaryGet } from "../../../../redux/slice/staffSalarySlice";
import Input from "../../../extra/Input";
const StaffReport = ({ handleSentRequest }) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { staffSalary } = useSelector((state) => state.staffSalary);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(staffSalaryGet(dialogueData._id));
  }, []);

  const handleSwitchChange = () => {
    dispatch(
      openDialog({
        type: "staffInfo",
        data: { ...dialogueData, payingRequest: true },
      })
    );
  };

  return (
    <div className="dialog">
      {/* <div className="w-100"> */}
      <div style={{ width: "97%" }}>
        <div className="row justify-content-center">
          <div className="col-xxl-6 col-xl-7 col-lg-10 col-12">
            <div className="mainDiaogBox position-relative  overflow-auto">
              <div className="row justify-content-between align-items-start formHead">
                <div className="col-8"></div>
                <div className="col-4">
                  <div
                    className="closeButton  position-absolute"
                    style={{ top: 10, right: 10 }}
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-circle-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-center justify-content-center">
                <div className="col-12 text-start">
                  <div className="mainLogoPart">
                    <div className="logoImg  width-sm-90 width-50 m-auto m10-bottom">
                      <img src={Logo} alt="logo" width={`100%`} />
                    </div>
                  </div>
                  <div className="staffImage m-auto m10-bottom hw-100 border-second-3 rounded-3 overflow-hidden">
                    <img
                      src={baseURL + staffSalary?.image}
                      alt="staff image"
                      width={"100%"}
                      height={"100%"}
                    />
                  </div>
                  <div className="FacultyName text-center text-second">
                    <h5>{staffSalary?.staffName} Info</h5>
                    <p className="fs-14 text-darkGray">
                      ({staffSalary?.position})
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="staffInfo">
                    <div className="row">
                      <div className="col-md-6 col-10 m-auto">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            Employee Information
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              Staff Salary
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.staffSalary} ₹`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              Staff Hours
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.staffHours} ₹`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            {staffSalary?.month} Details
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">Year</div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.year}`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">Month</div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.month}(${staffSalary?.monthId})`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">Days</div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.thisMonthDays} Days`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">Holidays</div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.fixHoliday} Days`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            {staffSalary?.month} Works
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              Working Day
                            </div>
                            <Input
                              type={`text`}
                              value={`${
                                staffSalary?.totalWorkingDays -
                                staffSalary?.manualHoliday
                              } Days`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              Extra Holiday
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.manualHoliday} Days`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              Work Hours
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.totalWorkingHours} Hours(${staffSalary?.totalWorkingDays} Day)`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">Holidays</div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.fixHoliday} Days`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            Staff Hours
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle m15-left">
                              Punch
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.staffPunchHours} Hours (${staffSalary?.average}%)`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox border-bottom-gray2-1">
                            <div className="staffInfoDetailTitle">
                              + Holiday Punch
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.manualHolidayHours} Hours(${staffSalary?.manualHoliday})`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              = Total Hours
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.staffWorkingHours} Hours`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            Staff Leave
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">Leave</div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.leaveHours} Hours`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox ">
                            <div className="staffInfoDetailTitle">
                              Paid Leave
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.paidLeaveHours} Hours`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            Staff Salary
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle m15-left">
                              Staff Salary
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.staffSalary}₹`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox border-bottom-gray2-1">
                            <div className="staffInfoDetailTitle">
                              / Work Hours
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.totalWorkingHours} Hours(${staffSalary?.totalWorkingDays} Day)`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              = Per Hour
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.staffPerHourSalary} ₹`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            Staff Work Hours
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle m15-left">
                              Paid Leave
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.paidLeaveHours} Hours`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox border-bottom-gray2-1">
                            <div className="staffInfoDetailTitle">
                              + Total Hours
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.staffWorkingHours} Hours`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              = Paid Hours
                            </div>
                            <Input
                              type={`text`}
                              value={`${staffSalary?.totalStaffWorkingHours} Hours`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 m-auto text-center">
                        {handleSentRequest && (
                          <>
                            {dialogueData?.payingRequest ? (
                              <ActionButton
                                className="bg-success-light text-success  border-success-1  m5-right"
                                bIcon={`ri-send-plane-fill`}
                                text={`Request Send`}
                              />
                            ) : (
                              <ActionButton
                                className="bg-blue-light text-blue  border-blue-1  m5-right"
                                onClick={() => {
                                  handleSentRequest(staffSalary);
                                  handleSwitchChange();
                                }}
                                bIcon={`ri-send-plane-fill`}
                                text={`Salary Request`}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffReport;
