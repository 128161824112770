import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import LogoSm from "../../assets/images/logoSm.png";
// import "../../assets/js/custom";
// import { warning } from "../util/Alert";
import { useDispatch, useSelector } from "react-redux";
import Navigator from "../extra/Navigator";
import $ from "jquery";
import { useEffect, useState } from "react";
import { logout } from "../../redux/slice/authSlice";
import { warning } from "../util/Alert";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginUser } = useSelector((state) => state.dashBoard);

  const handleLogout = async () => {
    const data = warning(
      "Logout",
      "All session will be remove?",
      "Logout",
      "ri-logout-circle-r-line"
    );
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(logout());
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  };

  const navBarArray = [
    {
      name: "Dashboard",
      path: "/admin/dashboard",
      navIcon: "ri-dashboard-2-fill",
    },
    {
      name: "Staff",
      path: "/admin/staff",
      navIcon: " ri-group-fill",
    },
    {
      name: "Satff Leave",
      navIcon: " ri-folder-transfer-fill",
      subMenu: [
        {
          subName: "Pending Leave",
          subPath: "/admin/pendingLeave",
        },
        {
          subName: "Accept Leave",
          subPath: "/admin/acceptLeave",
        },
        {
          subName: "Reject Leave",
          subPath: "/admin/rejectLeave",
        },
      ],
    },
    {
      name: "Attendance",
      path: "/admin/staffAttendance",
      navIcon: " ri-group-fill",
    },
    {
      name: "Staff Holiday",
      path: "/admin/staffHoliday",
      navIcon: " ri-group-fill",
    },
    {
      name: "Satff Salary",
      navIcon: " ri-folder-transfer-fill",
      subMenu: [
        {
          subName: "Pending Salary",
          subPath: "/admin/pendingSalary",
        },
        {
          subName: "Accept Salary",
          subPath: "/admin/acceptSalary",
        },
      ],
    },
    {
      name: "Salary Recepit",
      path: "/admin/salaryRecepts",
      navIcon: " ri-group-fill",
    },
    {
      name: "Salary",
      path: "/admin/salary",
      navIcon: " ri-group-fill",
    },
    {
      name: "Leave",
      path: "/admin/staffLeave",
      navIcon: " ri-group-fill",
    },
    {
      name: "Profile",
      path: "/admin/profile",
      navIcon: "ri-profile-fill",
    },
    {
      name: "Setting",
      path: "/admin/setting",
      navIcon: "ri-settings-2-fill",
    },
    {
      name: "LogOut",
      navIcon: " ri-arrow-right-circle-fill",
      onClick: handleLogout,
    },
  ];

  const categoryArray = [
    {
      categoryHead: "Admin",
      categoryPermission: true,
      categoryClass: "activeMenu",
      categoryBody: [
        {
          name: "Dashboard",
          path: "/admin/dashboard",
          navIcon: "ri-dashboard-line",
        },
        {
          name: "Profile",
          path: "/admin/profile",
          navIcon: "ri-profile-line",
        },
        {
          name: "Logout",
          navIcon: "ri-logout-circle-r-line",
          onClick: handleLogout,
        },
      ],
    },
    {
      categoryHead: "Student",
      categoryClass: "",
      // categoryPermission: loginUser?.isReceptionist ? true : false,
      categoryPermission: true,
      categoryBody: [
        {
          name: "Inquiry",
          navIcon: "ri-user-search-line",
          subMenu: [
            {
              subName: "Pending Leave",
              subPath: "/admin/pendingInquiry",
            },
            {
              subName: "Accept Leave",
              subPath: "/admin/acceptInquiry",
            },
            {
              subName: "Reject Leave",
              subPath: "/admin/rejectInquiry",
            },
          ],
        },
        {
          name: "Student",
          path: "/admin/student",
          navIcon: "ri-user-3-line",
        },
        {
          name: "Student Leave",
          navIcon: "ri-user-shared-2-line",
          subMenu: [
            {
              subName: "Pending Leave",
              subPath: "/admin/pendingStudentLeave",
            },
            {
              subName: "Accept Leave",
              subPath: "/admin/acceptStudentLeave",
            },
            {
              subName: "Reject Leave",
              subPath: "/admin/rejectStudentLeave",
            },
          ],
        },
        {
          name: "Student Atten..",
          path: "/admin/studentAttendance",
          navIcon: "ri-user-follow-line",
        },
        {
          name: "Student Holiday",
          path: "/admin/studentHoliday",
          navIcon: "ri-calendar-event-line",
        },
        {
          name: "Fees",
          navIcon: "ri-wallet-3-line",
          subMenu: [
            {
              subName: "Receipt",
              subPath: "/admin/receipt",
            },
            {
              subName: "Reminding Fees",
              subPath: "/admin/remindingFee",
            },
          ],
        },
        {
          name: "Courses",
          path: "/admin/courses",
          navIcon: "ri-booklet-line",
        },
        {
          name: "Batch",
          path: "/admin/batch",
          navIcon: "ri-group-line",
        },
      ],
    },
    {
      categoryHead: "HR",
      categoryClass: "",
      // categoryPermission: loginUser?.isHr ? true : false,
      categoryPermission: true,
      categoryBody: [
        {
          name: "Staff",
          path: "/admin/staff",
          navIcon: "ri-user-2-line",
        },
        {
          name: "Satff Leave",
          navIcon: "ri-user-shared-2-line",
          subMenu: [
            {
              subName: "Pending Leave",
              subPath: "/admin/pendingLeave",
            },
            {
              subName: "Accept Leave",
              subPath: "/admin/acceptLeave",
            },
            {
              subName: "Reject Leave",
              subPath: "/admin/rejectLeave",
            },
          ],
        },
        {
          name: "Staff Holiday",
          path: "/admin/staffHoliday",
          navIcon: "ri-calendar-check-line",
        },
        {
          name: "Satff Salary",
          navIcon: "ri-bank-card-line",
          subMenu: [
            {
              subName: "Pending Salary",
              subPath: "/admin/pendingSalary",
            },
            {
              subName: "Accept Salary",
              subPath: "/admin/acceptSalary",
            },
            {
              subName: "Reserve Salary",
              subPath: "/admin/reserveSalary",
            },
          ],
        },
        {
          name: "Salary Recepit",
          path: "/admin/salaryRecepts",
          navIcon: "ri-file-paper-2-line",
        },
      ],
    },
  ];

  var webSize = $(window).width();
  return (
    <div className="mainSidebar">
      <SideMenuJS datas={loginUser} />
      <div className="sideBar">
        <div className="sideBarLogo">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="smallLogo">
            <img src={LogoSm} alt="logo" className="smallLogo" />
          </div>
          <i className="ri-close-line closeIcon navToggle"></i>
          <div className="blackBox navToggle"></div>
        </div>
        {/* ======= Navigation ======= */}
        <div className="navigation">
          <nav>
            <div className="categoryWiseSideBar">
              {categoryArray.map((categories) => {
                return (
                  categories.categoryPermission === true && (
                    <div className={`mainSideBar  ${categories.categoryClass}`}>
                      <div className="sideBarHead betBox">
                        <span className="text-overflow-1 d-block">
                          {categories.categoryHead}
                        </span>
                        <i class="ri-arrow-down-s-line"></i>
                      </div>
                      <ul
                        className={`mainMenu ${
                          webSize < 991 ? "mobMenu" : " webMenu"
                        }`}
                      >
                        {categories.categoryBody.map((res, i) => {
                          return (
                            <>
                              <Navigator
                                name={res?.name}
                                path={res?.path}
                                navIcon={res?.navIcon}
                                onClick={res?.onClick && res?.onClick}
                              >
                                {res?.subMenu && (
                                  <ul className={`subMenu`}>
                                    <span className="subhead">{res?.name}</span>
                                    {res?.subMenu?.map((subMenu) => {
                                      console.log("data", subMenu);
                                      return (
                                        <Navigator
                                          name={subMenu.subName}
                                          path={subMenu.subPath}
                                          onClick={subMenu.onClick}
                                        />
                                      );
                                    })}
                                  </ul>
                                )}
                              </Navigator>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  )
                );
              })}
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

export const SideMenuJS = ({ datas }) => {
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    // ============== sidemenu toggle ==================
    $(".deactiveClick:not(.activeMasterMenu) .subMenu").hide();
    const handleNav = (event) => {
      const target = event.currentTarget;
      $(".subMenu").not($(target).next(".subMenu")).slideUp();
      $(".mainMenu i").not($(target).children("i")).removeClass("rotate90");
      $(target).next(".subMenu").slideToggle();
      $(target).children("i").toggleClass("rotate90");
    };
    $(".mainMenu.webMenu > li > a").on("click", handleNav);

    // ============== sidebar toggle ==================
    const handleSidebar = () => {
      // Sidemenu Off In Mobile Menu
      $(".subMenu").slideUp();
      $(".mainMenu i").removeClass("rotate90");
      // Mobile Menu Class
      $(".mainAdminGrid").toggleClass("webAdminGrid");
      $(".mainMenu").toggleClass("mobMenu webMenu");
      setMenu(menu ? false : true);
    };
    $(".navToggle").on("click", handleSidebar);

    const handleDefault = () => {
      $(".mainMenu > li").removeClass("beforeActiveMaster afterActiveMaster");
      $(".activeMasterMenu").prev().addClass("beforeActiveMaster");
      $(".activeMasterMenu").next().addClass("afterActiveMaster");
    };
    // ============== menu master toggle ==================
    const handleMasterMenu = () => {
      setTimeout(() => {
        handleDefault();
      }, 10);
    };
    $(".mainMenu > li.activeClick").on("click", handleMasterMenu);

    // ============== menu master toggle ==================
    const handleMasterSubMenu = () => {
      setTimeout(() => {
        handleDefault();
      }, 10);
    };
    $(".mainMenu > li.deactiveClick > .subMenu > li").on(
      "click",
      handleMasterSubMenu
    );
    handleDefault();

    // ============== sidemenu toggle ==================

    const handleCategory = (event) => {
      const target = event.currentTarget;
      $(target).next(".mainMenu").slideToggle();
      $(target).children("i").toggleClass("rotate180");
    };
    $(".categoryWiseSideBar .mainSideBar:not(.activeMenu) > .sideBarHead").on(
      "click",
      handleCategory
    );

    return () => {
      $(".mainMenu > li > a").off("click", handleNav);
      $(".navToggle").off("click", handleSidebar);
      $(
        ".categoryWiseSideBar .mainSideBar:not(.activeMenu) > .sideBarHead"
      ).off("click", handleCategory);
      $(".mainMenu > li.activeClick").off("click", handleMasterMenu);
      $(".mainMenu > li.deactiveClick > .subMenu > li").off(
        "click",
        handleMasterSubMenu
      );
    };
  }, [menu, datas]);

  return null;
};
