import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dashBoardGet,
  employeeDashData,
  getLoginUser,
} from "../../../redux/slice/dashBoardSlice";
import Title from "../../extra/Title";

const Dashboard = () => {
  const { dashBoard, loginUser } = useSelector((state) => state.dashBoard);

  const { admin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getLoginUser(admin._id));
  }, [admin._id]);

  useEffect(() => {
    dispatch(dashBoardGet({ command: true }));
  }, []);

  useEffect(() => {
    if (admin?._id) {
      dispatch(
        employeeDashData({
          staffId: admin._id,
          monthId: 3,
          year: 2024,
          command: true,
        })
      );
    }
  }, [admin._id]);

  useEffect(() => {
    setData(dashBoard);
  }, [dashBoard]);

  return (
    <div className="mainDashboard">
      <div className="dashBoardHead betBox">
        <div className="dashHeadText">
          <Title name={"Dashboard"} icon={`ri-home-5-line`} />
        </div>
      </div>

      <div className="dashBoardBody">
        <div className="hrSystem m20-bottom p20-bottom border-bottom-gray2-1">
          <Title name={`Employee Data`} className={`m10-bottom`} />
          <div className="row">
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Staff`}
                count={dashBoard?.totalStaff}
                className={`p10`}
                classNameBody={`fs-22 text-success`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`success`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Staff Leave`}
                count={dashBoard?.totalStaffLeave}
                className={`p10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Staff Holiday`}
                count={dashBoard?.totalStaffHoliday}
                className={`p10`}
                classNameBody={`fs-22 text-success`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`success`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Staff Receipt`}
                count={dashBoard?.totalStaffSalaryReceipt}
                className={`p10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
          </div>
        </div>

        <div className="reptionistSystem m20-bottom p20-bottom">
          <Title name={`Student Data`} className={`m10-bottom`} />
          <div className="row">
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Student`}
                count={dashBoard?.totalStudent}
                className={`p10`}
                classNameBody={`fs-22 text-orange`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`orange`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Student Leave`}
                count={dashBoard?.totalStudentLeave}
                className={`p10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Student Holiday`}
                count={dashBoard?.totalStudentHoliday}
                className={`p10`}
                classNameBody={`fs-22 text-orange`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`orange`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Student Course`}
                count={dashBoard?.totalStudentCourse}
                className={`p10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Student Batch`}
                count={dashBoard?.totalStudentBatch}
                className={`p10`}
                classNameBody={`fs-22 text-orange`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`orange`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Student Receipt`}
                count={dashBoard?.totalStudentReceipt}
                className={`p10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
          </div>
        </div>

        {/* <div className="employeeSystem">
          <Title name={`All User Data`} className={`m10-bottom text-second`} />
          <div className="row">
            <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Employee`}
                count={staffAllAttendance?.totalHours}
                className={`p10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
            <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Break Hours`}
                count={staffAllAttendance?.totalBreakHours}
                className={`p10`}
                classNameBody={`fs-22 text-second`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`second`}
              />
            </div>
            <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Working Hours`}
                count={staffAllAttendance?.finalTotal?.toFixed(2)}
                className={`p10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
          </div>
          <Title
            name={`March Punch Data`}
            className={`m10-bottom m20-top text-second`}
          />
          <div className="row">
            <div className="col-md-7 col-12">
              <div className="row employeeDash">
                <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                  <DashBox
                    title={`Year`}
                    count={thisMonthData?.year}
                    className={`p10`}
                    classNameBody={`fs-22 text-darkGray`}
                    classNameHead={`fs-14`}
                    icon={`ri-calendar-event-line`}
                    color={`darkGray`}
                  />
                </div>
                <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                  <DashBox
                    title={`Month`}
                    count={thisMonthData?.month}
                    className={`p10`}
                    classNameBody={`fs-22 text-second`}
                    classNameHead={`fs-14`}
                    icon={`ri-calendar-todo-line`}
                    color={`second`}
                  />
                </div>
                <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                  <DashBox
                    title={`Punch Hours`}
                    count={thisMonthData?.totalHours}
                    className={`p10`}
                    classNameBody={`fs-22 text-darkGray`}
                    classNameHead={`fs-14`}
                    icon={`ri-calendar-check-line`}
                    color={`darkGray`}
                  />
                </div>
                <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                  <DashBox
                    title={`Break Hours`}
                    count={thisMonthData?.breakHours}
                    className={`p10`}
                    classNameBody={`fs-22 text-second`}
                    classNameHead={`fs-14`}
                    icon={`ri-cup-line`}
                    color={`second`}
                  />
                </div>
                <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                  <DashBox
                    title={`Total Hours`}
                    count={thisMonthData?.totalWorkingHours}
                    className={`p10`}
                    classNameBody={`fs-22 text-darkGray`}
                    classNameHead={`fs-14`}
                    icon={`ri-hourglass-fill`}
                    color={`darkGray`}
                  />
                </div>
                <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                  <DashBox
                    title={`Holiday`}
                    count={thisMonthData?.holiday}
                    className={`p10`}
                    classNameBody={`fs-22 text-second`}
                    classNameHead={`fs-14`}
                    icon={`ri-calendar-close-line`}
                    color={`second`}
                  />
                </div>
              </div>
            </div>
            <div className=" col-md-5 col-12 m15-bottom">
              <Chart
                chartType="PieChart"
                width="100%"
                height="320px"
                data={pieChartDataAll}
                options={pieChartOptions}
                className="pieChart"
              />
            </div>
            <div className="col-12">
              <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={lineChartDataAll}
                options={lineChartOptions}
                className="lineChart"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;

export const DashBox = ({
  title,
  count,
  color,
  icon,
  sideText,
  className,
  classNameHead,
  classNameBody,
}) => {
  return (
    <div
      className={`dashBox betBox align-items-end ${className} border-bottom-${color}-3 bg-light`}
    >
      <div>
        <div className={`boxHead betBox`}>
          <p className={`${classNameHead}`}>{title}</p>
        </div>
        <div className={`boxBody m5-sm-top m2-top d-flex align-items-end`}>
          <h2 className={`m0 ${classNameBody}`} style={{ color: `${color}` }}>
            {count}
          </h2>
          <p className="fs-12 m5-left text-darkGary fw-500">{sideText}</p>
        </div>
      </div>

      {icon && (
        <div
          className={`boxIcon m20-md-right m12-right d-flex justify-content-center align-items-center fs-sm-22 fs-20 hw-md-35 hw-25 bg-${color} text-light`}
          style={{
            color: `${color}`,
            backgroundColor: `${color}40`,
            borderRadius: "7px",
          }}
        >
          <i className={icon}></i>
        </div>
      )}
    </div>
  );
};
