import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../extra/Input";
import Button from "../../../extra/Button";
import { editData, generateNum, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  inquiryAdd,
  inquiryUpdate,
} from "../../../../redux/slice/inquirySlice";

const InquiryAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addInquiry = submitData(e);
    if (addInquiry) {
      // console.log("addInquiry", addInquiry);
      // const formData = objectToFormData(addInquiry);
      try {
        let response;
        if (dialogueData) {
          const payload = { addInquiry, inquiryId: dialogueData._id };
          response = await dispatch(inquiryUpdate(payload)).unwrap();
        } else {
          response = await dispatch(inquiryAdd(addInquiry)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const dispatch = useDispatch();
  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-8 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-start formHead">
                <div className="col-8">
                  <h3 className="m20-bottom headTitle">Inquiry Dialog</h3>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton "
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i class="ri-close-circle-line"></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="inquireForm">
                <div className="row align-items-start formBody  p100-bottom">
                  <div className="col-lg-6 col-12">
                    <Input
                      type={`text`}
                      id={`name`}
                      name={`name`}
                      label={`Name`}
                      errorMessage={`Enter Name`}
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <Input
                      type={`number`}
                      id={`inquiryId`}
                      name={`inquiryId`}
                      label={`Form Code`}
                      errorMessage={`Enter Form Code`}
                      validation={`^[a-zA-Z0-9]{6,6}$`}
                      validationError={`Invalid Form Code`}
                      activeIcon={!dialogueData && `ri-information-line`}
                      activClick={() => generateNum(6, "inquiryId")}
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <Input
                      type={`number`}
                      id={`mobileNo`}
                      name={`mobileNo`}
                      label={`Mobile Number`}
                      errorMessage={`Enter Mobile Number`}
                      validation={`^[0-9]{10,10}$`}
                      validationError={`Invalid Mobile Number`}
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <Input
                      type={`text`}
                      id={`course`}
                      name={`course`}
                      label={`Course`}
                      errorMessage={`Enter Course`}
                    />
                  </div>

                  <div className="col-lg-3 col-6 align-self-center">
                    <Input
                      type={`radio`}
                      id={`male`}
                      name={`gender`}
                      label={`Male`}
                      value={`male`}
                    />
                  </div>
                  <div className="col-lg-3 col-6 align-self-center">
                    <Input
                      type={`radio`}
                      id={`female`}
                      name={`gender`}
                      label={`Female`}
                      value={`female`}
                    />
                  </div>
                </div>
                <div className="row m20-top formFooter">
                  <div className="col-12 text-end m0">
                    <Button
                      className={`bg-gray2 text-darkGray border-darkGray-2`}
                      text={`Cancel`}
                      type={`button`}
                      onClick={() => dispatch(closeDialog())}
                    />
                    <Button
                      type={`submit`}
                      className={`bg-second text-light border-second-light-2 m10-left`}
                      text={`Submit`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryAdd;
