import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../extra/Input";
import Button, { ActionButton } from "../../../extra/Button";
import { editData, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  staffHolidayAdd,
  staffHolidayUpdate,
} from "../../../../redux/slice/staffHolidaySlice";

const StaffHolidayAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [isManual, setIsManual] = useState(false);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
      setIsManual(dialogueData?.isManual);
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addHoliday = submitData(e);
    if (addHoliday) {
      let payload = { ...addHoliday, isManual };
      try {
        let response;
        if (dialogueData) {
          const editHoliday = {
            ...payload,
            staffHolidayId: dialogueData._id,
          };
          response = await dispatch(staffHolidayUpdate(editHoliday)).unwrap();
        } else {
          response = await dispatch(staffHolidayAdd(payload)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response?.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const dispatch = useDispatch();
  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-8 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-start formHead">
                <div className="col-8">
                  <h3 className="m20-bottom headTitle">Staff Holiday Dialog</h3>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton "
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i class="ri-close-circle-line"></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="staffHolidaysForm">
                <div className="row align-items-start formBody p50-bottom">
                  <div className="col-lg-6 col-12">
                    <Input
                      type={`date`}
                      id={`holidayDate`}
                      name={`holidayDate`}
                      label={`Holiday Date`}
                      errorMessage={`Enter Holiday Date`}
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <Input
                      type={`text`}
                      id={`description`}
                      name={`description`}
                      label={`Description`}
                      errorMessage={`Enter Description`}
                    />
                  </div>

                  <div className="col-lg-6 col-12">
                    <div className="inputData">
                      <label>Manual Holiday ?</label>
                    </div>
                    <ActionButton
                      type={`button`}
                      className={`leaveType fs-14 ${
                        isManual
                          ? "bg-success text-light"
                          : "bg-success-light text-success"
                      }  border-success-2 m10-right m5-top`}
                      text={`Yes`}
                      onClick={() => setIsManual(true)}
                    />
                    <ActionButton
                      type={`button`}
                      className={`leaveType fs-14 ${
                        !isManual
                          ? "bg-danger text-light"
                          : "bg-danger-light text-danger"
                      }  border-danger-2 m10-right m5-top`}
                      text={`No`}
                      onClick={() => setIsManual(false)}
                    />
                  </div>
                </div>
                <div className="row m20-top formFooter">
                  <div className="col-12 text-end m0">
                    <Button
                      className={`bg-gray2 text-darkGray border-darkGray-2`}
                      text={`Cancel`}
                      type={`button`}
                      onClick={() => dispatch(closeDialog())}
                    />
                    <Button
                      type={`submit`}
                      className={`bg-second text-light border-second-light-2 m10-left`}
                      text={`Submit`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffHolidayAdd;
