import React from "react";

const ToggleSwitch = (props) => {
  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          checked={props.value}
          onClick={props.onClick}
          // className="customCheckbox"
          className="toggle-checkbox"
        />
        {/* <div class="slider"></div> */}
        <div class="toggle-slot">
          <div class="sun-icon-wrapper">
            <div
              class="iconify sun-icon"
              data-icon="feather-sun"
              data-inline="false"
            ></div>
          </div>
          <div class="toggle-button"></div>
          <div class="moon-icon-wrapper">
            <div
              class="iconify moon-icon"
              data-icon="feather-moon"
              data-inline="false"
            ></div>
          </div>
        </div>
      </label>
    </>
  );
};

export default ToggleSwitch;
