import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import { studentFees } from "../../../../redux/slice/studentSlice";
import { baseURL } from "../../../util/config";
import Logo from "../../../../assets/images/logo.png";
import Input, { Textarea } from "../../../extra/Input";

const StudentDetails = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xxl-9 col-xl-10 col-lg-11 col-12">
            <div className="mainDiaogBox position-relative overflow-auto">
              <div
                className="closeButton position-absolute"
                style={{ top: 10, right: 10 }}
                onClick={() => {
                  dispatch(closeDialog());
                }}
              >
                <i className="ri-close-circle-line"></i>
              </div>

              {/* <div className="row">
                <div className="col-xl-4 col-lg-6 border-right-gray-1 text-start">
                  <div className="mainLogoPart">
                    <div className="logoImg  width-sm-90 width-50 m-auto m10-bottom">
                      <img src={Logo} alt="logo" width={`100%`} />
                    </div>
                  </div>
                  <div className="studentImage m-auto m10-bottom hw-150 border-second-3 rounded-5 overflow-hidden">
                    <img
                      src={baseURL + dialogueData?.studentPhoto}
                      alt="student image"
                      width={"100%"}
                      height={"100%"}
                    />
                  </div>
                  <div className="FacultyName text-center text-second">
                    <h4>{dialogueData?.studentName}</h4>
                  </div>
                  <table className="studentDetails fs-md-14 fs-12 text-capitalize w-100">
                    <tr className="m5-bottom fw-600">
                      <td className="fw-bold text-dark text-end">
                        Father Name :{" "}
                      </td>
                      <td>{dialogueData?.fatherName}</td>
                    </tr>
                    <tr className="m5-bottom fw-600">
                      <td className="fw-bold text-dark text-end">Gender : </td>
                      <td>{dialogueData?.gender}</td>
                    </tr>
                    <tr className="m5-bottom fw-600">
                      <td className="fw-bold text-dark text-end">
                        Date of Birth :{" "}
                      </td>
                      <td>{dialogueData?.dob}</td>
                    </tr>
                    <tr className="m5-bottom fw-600">
                      <td className="fw-bold text-dark text-end">course : </td>
                      <td>{dialogueData?.course}</td>
                    </tr>
                    <tr className="m5-bottom fw-600 ">
                      <td className="fw-bold text-dark text-end">Email : </td>
                      <td className="text-lowercase">{dialogueData?.email}</td>
                    </tr>
                    <tr className="m5-bottom fw-600">
                      <td className="fw-bold text-dark text-end">
                        Mobile Number :{" "}
                      </td>
                      <td>{dialogueData?.phoneNo}</td>
                    </tr>
                    <tr className="m5-bottom fw-600">
                      <td className=" fw-bold text-dark text-end">
                        Joining Date :{" "}
                      </td>
                      <td className="text-second">
                        {new Date(dialogueData.createdAt).toLocaleString()}
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="col-xl-8 col-lg-6 m0-lg-top m20-top adjustHeight">
                  <h5 className="text-second">Personal Details :</h5>
                  <div className="row align-items-start formBody">
                    <div className="col-md-6 col-12">
                      <div className="showBox fs-md-14 fs-12 m10-top ">
                        <div className="fw-bold text-dark">
                          Education Level:
                        </div>
                        <div className="showField border-gray-1 rounded-2 p10 m10-top">
                          {dialogueData?.educationLevel}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="showBox fs-md-14 fs-12 m10-top ">
                        <div className="fw-bold text-dark">
                          WhatsApp Number:
                        </div>
                        <div className="showField border-gray-1 rounded-2 p10 m10-top">
                          {dialogueData?.whatsappNo}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="showBox fs-md-14 fs-12 m10-top ">
                        <div className="fw-bold text-dark">Father Number:</div>
                        <div className="showField border-gray-1 rounded-2 p10 m10-top">
                          {dialogueData?.fatherNo}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="showBox fs-md-14 fs-12 m10-top ">
                        <div className="fw-bold text-dark">Aadhar Card :</div>
                        <div className="showField border-gray-1 rounded-2 p10 m10-top">
                          {dialogueData?.adharCard}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4  col-md-6 col-12">
                      <div className="showBox fs-md-14 fs-12 m10-top ">
                        <div className="fw-bold text-dark">Address :</div>
                        <div className="showField border-gray-1 rounded-2 p10 m10-top">
                          {dialogueData?.address}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4  col-md-6 col-12">
                      <div className="showBox fs-md-14 fs-12 m10-top ">
                        <div className="fw-bold text-dark">Batch Time :</div>
                        <div className="showField border-gray-1 rounded-2 p10 m10-top">
                          {dialogueData?.batchTime}
                        </div>
                      </div>
                    </div>
                  </div>

                  <h5 className="text-second m20-top">Fees Details :</h5>
                  <div className="row align-items-start formBody">
                    <div className="col-md-6 col-12">
                      <div className="showBox fs-md-14 fs-12 m10-top ">
                        <div className="fw-bold text-dark">Total Fees:</div>
                        <div className="showField border-gray-1 rounded-2 p10 m10-top">
                          {dialogueData?.totalFees}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="mainProfileBoard mainAdminProfile">
                <div className="mainProfileBox">
                  <div className="row">
                    <div className=" col-12 m-auto">
                      <div className=" text-center">
                        <div className="profileNameImage p50-x d-flex align-items-center flex-column justify-content-center">
                          <div className="profileMainImage m0-top m30-sm-right">
                            <div className="adminProfileImage hw-lg-120 hw-md-100 hw-80 m-auto  position-relative">
                              <div className="adminImageHeader hw-lg-120 hw-md-100 hw-80 m-auto overflow-hidden border-second-3">
                                <img
                                  src={baseURL + dialogueData?.studentPhoto}
                                  alt="admin profile"
                                  height={`100%`}
                                  width={`100%`}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="adminText m20-top text-center">
                            <h3 className="m0 fs-lg-24 fs-sm-20 fs-xsm-16 fs-14 text-second">
                              {dialogueData?.studentName}
                            </h3>
                            <p className="fs-lg-16 fs-xsm-12 fs-12">
                              {dialogueData?.email}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row m40-sm-top m20-top">
                        <div className="col-sm-6 col-12">
                          <div className="adminDetails bg-light p20">
                            <div className="adminProfileTitle">
                              Personal Information
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Name</div>
                              <Input
                                type={`text`}
                                value={dialogueData?.studentName}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">
                                Date of birth
                              </div>
                              <Input
                                type={`date`}
                                value={dialogueData?.dob}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox ">
                              <div className="adminDetailTitle">Gender</div>
                              <Input
                                type={`text`}
                                value={dialogueData?.gender}
                                defaultValue={`--`}
                                disabled={true}
                                className={`text-capitalize`}
                              />
                            </div>
                            <div className="adminDetailsBox ">
                              <div className="adminDetailTitle">Age</div>
                              <Input
                                type={`text`}
                                value={dialogueData?.age}
                                defaultValue={`--`}
                                disabled={true}
                                className={`text-capitalize`}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Addhar No</div>
                              <Input
                                type={`text`}
                                value={dialogueData?.adharCard}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">
                                Whatsapp No
                              </div>
                              <Input
                                type={`text`}
                                value={`+91 ${dialogueData?.whatsappNo}`}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">
                                Qulification
                              </div>
                              <Input
                                type={`text`}
                                value={dialogueData?.educationLevel}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Address</div>
                              <Textarea
                                row={2}
                                type={`date`}
                                value={dialogueData?.address}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                          </div>

                          <div className="adminDetails bg-light p20">
                            <div className="adminProfileTitle">
                              Emergency Contect
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">
                                Father Name
                              </div>
                              <Input
                                type={`text`}
                                value={dialogueData?.fatherName}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Phone No</div>
                              <Input
                                type={`text`}
                                value={`+91 ${dialogueData?.fatherNo}`}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="adminDetails bg-light p20">
                            <div className="adminProfileTitle">
                              General Information
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Form No</div>
                              <Input
                                type={`text`}
                                value={dialogueData?.formNo}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Course</div>
                              <Input
                                type={`text`}
                                value={dialogueData?.course}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Fees</div>
                              <Input
                                type={`text`}
                                value={`${dialogueData?.totalFees} ₹`}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Batch Name</div>
                              <Input
                                type={`text`}
                                value={dialogueData?.batchName}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Batch Time</div>
                              <Input
                                type={`text`}
                                value={dialogueData?.batchTime}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">
                                Course Start
                              </div>
                              <Input
                                type={`date`}
                                value={dialogueData?.admissionDate}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="adminDetails bg-light p20">
                            <div className="adminProfileTitle">
                              Contact Details
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Email</div>
                              <Input
                                type={`text`}
                                value={dialogueData?.email}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                            <div className="adminDetailsBox">
                              <div className="adminDetailTitle">Phone No</div>
                              <Input
                                type={`text`}
                                value={`+91 ${dialogueData?.phoneNo}`}
                                defaultValue={`--`}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDetails;
