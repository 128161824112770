import React from "react";

const Title = (props) => {
  const { name, subName, className, icon } = props;
  return (
    <div
      className={`mainTitle fs-md-16 fs-sm-14 fs-12 d-flex align-items-center cursor-pointer ${className}`}
    >
      {icon && (
        <div className="titleIcon  hw-md-30 hw-25 fs-md-16 fs-14 m13-md-right m8-right midBox">
          <i className={icon}></i>
        </div>
      )}
      <div className="title text-uppercase fw-700 ">
        {subName ? subName : name}
      </div>
    </div>
  );
};

export default Title;
