import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../extra/Input";
import Button from "../../../extra/Button";
import { editData, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import { courseAdd, courseUpdate } from "../../../../redux/slice/courseSlice";

const CourseAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addCourse = submitData(e);

    if (addCourse) {
      try {
        let response;
        if (dialogueData) {
          const payload = { addCourse, id: dialogueData._id };
          response = await dispatch(courseUpdate(payload)).unwrap();
        } else {
          response = await dispatch(courseAdd(addCourse)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const dispatch = useDispatch();
  return (
    <div className="dialog">
      <div style={{ width: "35%" }}>
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-sm-10 col-12">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-start formHead">
                <div className="col-8">
                  <h3 className="headTitle m20-bottom">Course Dialog</h3>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i class="ri-close-circle-line"></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="courseForm">
                <div className="row align-items-start formBody">
                  <div className="col-lg-6 col-12">
                    <Input
                      type={`text`}
                      id={`courseName`}
                      name={`courseName`}
                      label={`course`}
                      errorMessage={`Enter course`}
                    />
                  </div>
                </div>

                <div className="row m5-top formFooter p0">
                  <div className="col-12 text-end m0">
                    <Button
                      className={`bg-gray2 text-darkGray border-darkGray-2`}
                      text={`Cancel`}
                      type={`button`}
                      onClick={() => dispatch(closeDialog())}
                    />
                    <Button
                      type={`submit`}
                      className={`bg-second text-light border-second-light-2 m10-left`}
                      text={`Submit`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseAdd;
