import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  staffHoliday: [],
  staffHolidayTotal: 0,
  isLoading: false,
  isSkeleton: false,
};
export const staffHolidayGet = createAsyncThunk(
  "staffHoliday/showstaffHoliday",
  async (payload) => {
    return apiInstance.get(`staffHoliday/showstaffHoliday?year=${payload}`);
  }
);
export const staffHolidayAdd = createAsyncThunk(
  "staffHoliday/createstaffHoliday",
  async (payload) => {
    return apiInstance.post("staffHoliday/createstaffHoliday", payload);
  }
);
export const staffHolidayUpdate = createAsyncThunk(
  "staffHoliday/updatestaffHoliday",
  async (payload) => {
    return apiInstance.patch(
      `staffHoliday/updatestaffHoliday?staffHolidayId=${payload.staffHolidayId}`,
      payload
    );
  }
);
export const staffHolidayDelete = createAsyncThunk(
  "staffHoliday/deletestaffHoliday",
  async (payload) => {
    return apiInstance.delete(
      `staffHoliday/deletestaffHoliday?staffHolidayId=${payload}`
    );
  }
);

const staffHolidaySlice = createSlice({
  name: "staffHolidaySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // staffHolidayGet
    builder.addCase(staffHolidayGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(staffHolidayGet.fulfilled, (state, action) => {
      state.staffHoliday = action.payload.staffHoliday;
      state.staffHolidayTotal = action.payload.holidayTotal;
      state.isSkeleton = false;
    });
    builder.addCase(staffHolidayGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // holidayCreate
    builder.addCase(staffHolidayAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffHolidayAdd.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.staffHoliday.unshift(action.payload.staffHoliday);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffHolidayAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // staffHolidayUpdate
    builder.addCase(staffHolidayUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffHolidayUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const holidayIdx = state.staffHoliday.findIndex(
          (holiday) => holiday._id === action.payload.staffHoliday._id
        );
        if (holidayIdx !== -1) {
          state.staffHoliday[holidayIdx] = {
            ...state.staffHoliday[holidayIdx],
            ...action.payload.staffHoliday,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffHolidayUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // staffHolidayDelete
    builder.addCase(staffHolidayDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffHolidayDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.staffHoliday = state.staffHoliday.filter(
          (holiday) => holiday._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffHolidayDelete.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default staffHolidaySlice.reducer;
