import { useDispatch, useSelector } from "react-redux";
import Button from "../../../extra/Button";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import { baseURL } from "../../../util/config";
import Logo from "../../../../assets/images/logo.png";
import Input from "../../../extra/Input";

const StaffLeaveDetails = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { admin } = useSelector((state) => state.auth);
  console.log("admin", admin);
  const dispatch = useDispatch();
  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-11">
            <div className="mainDiaogBox position-relative overflow-auto">
              <div
                className="closeButton  position-absolute"
                style={{ top: 10, right: 10 }}
                onClick={() => {
                  dispatch(closeDialog());
                }}
              >
                <i className="ri-close-circle-line"></i>
              </div>

              <div className="row">
                <div className="col-xl-12">
                  <div className="mainLogoPart">
                    <div className="logoImg  width-sm-90 width-50 m-auto m10-bottom">
                      <img src={Logo} alt="logo" width={`100%`} />
                    </div>
                  </div>
                  <div className="staffImage m-auto m10-bottom hw-100 border-second-3 rounded-2 overflow-hidden">
                    <img
                      src={baseURL + (dialogueData?.image || admin?.image)}
                      alt="staff image"
                      width={"100%"}
                      height={"100%"}
                    />
                  </div>
                  <div className="FacultyName text-center text-second">
                    <h5>{dialogueData?.staffName || admin?.staffName}</h5>
                  </div>
                  <div className="Leave Status text-center text-second m20-y">
                    {dialogueData?.isLeave ? (
                      <Button
                        className={`bg-danger-light text-danger border-danger-1 p20-x p4-y fs-12 text-capitalize p6-x p3-y fs-10 fw-600 rounded-3 m10-right`}
                        text={`Full Leave`}
                      />
                    ) : (
                      <Button
                        className={`bg-darkGray-light text-darkGray border-darkGray-1 p20-x p4-y fs-12 text-capitalize p6-x p3-y fs-10 fw-600 rounded-3 m10-right`}
                        text={`Half Leave`}
                      />
                    )}
                    {dialogueData?.status == 1 ? (
                      <Button
                        className={`bg-blue-light text-blue border-blue-1 p20-x p4-y fs-12 text-capitalize p6-x p3-y fs-10 fw-600 rounded-3`}
                        text={`Pending`}
                      />
                    ) : dialogueData?.status == 2 ? (
                      <Button
                        className={`bg-success-light text-success border-success-1 p20-x p4-y fs-12 text-capitalize p6-x p3-y fs-10 fw-600 rounded-3`}
                        text={`Accepted`}
                      />
                    ) : (
                      dialogueData?.status == 3 && (
                        <Button
                          className={`bg-danger-light text-danger border-danger-1 p20-x p4-y fs-12 text-capitalize p6-x p3-y fs-10 fw-600 rounded-3`}
                          text={`Rejected`}
                        />
                      )
                    )}
                  </div>
                  <div className="mainLeaveDetails w-100 overflow-auto">
                    <div className="mainProfileBoard mainAdminProfile">
                      <div className="mainProfileBox">
                        <div className="row">
                          <div className=" col-smm-6 col-12">
                            <div className="row">
                              <div className=" col-12">
                                <div className="adminDetails p20">
                                  <div className="adminProfileTitle">
                                    Leave Date
                                  </div>

                                  <div className="adminDetailsBox">
                                    <div className="adminDetailTitle">
                                      Date Start
                                    </div>
                                    <Input
                                      type={`date`}
                                      value={dialogueData?.leaveFrom}
                                      defaultValue={`--`}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="adminDetailsBox">
                                    <div className="adminDetailTitle">
                                      Date End
                                    </div>
                                    <Input
                                      type={`date`}
                                      value={dialogueData?.leaveTo}
                                      defaultValue={`--`}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="adminDetailsBox ">
                                    <div className="adminDetailTitle">
                                      Leave Days
                                    </div>
                                    <Input
                                      type={`text`}
                                      value={`${dialogueData?.allDays} Days`}
                                      defaultValue={`--`}
                                      disabled={true}
                                      className={`text-capitalize`}
                                    />
                                  </div>
                                  <div className="adminDetailsBox">
                                    <div className="adminDetailTitle">
                                      Holidays
                                    </div>
                                    <Input
                                      type={`text`}
                                      value={`- ${dialogueData?.holidays} Days`}
                                      defaultValue={`--`}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="adminDetailsBox border-top-gray2-1">
                                    <div className="adminDetailTitle">
                                      Total Days
                                    </div>
                                    <Input
                                      type={`text`}
                                      value={`= ${dialogueData?.totalDays} Days`}
                                      defaultValue={`--`}
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" col-smm-6 col-12">
                            <div className="row">
                              <div className=" col-12">
                                <div className="adminDetails p20">
                                  <div className="adminProfileTitle">
                                    Leave Time
                                  </div>

                                  <div className="adminDetailsBox">
                                    <div className="adminDetailTitle">
                                      Time Start
                                    </div>
                                    <Input
                                      type={`time`}
                                      value={dialogueData?.timeFrom}
                                      defaultValue={`--`}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="adminDetailsBox">
                                    <div className="adminDetailTitle">
                                      Time End
                                    </div>
                                    <Input
                                      type={`time`}
                                      value={dialogueData?.timeTo}
                                      defaultValue={`--`}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="adminDetailsBox ">
                                    <div className="adminDetailTitle">
                                      Leave Time
                                    </div>
                                    <Input
                                      type={`text`}
                                      value={`${dialogueData?.allHours} Hr`}
                                      defaultValue={`--`}
                                      disabled={true}
                                      className={`text-capitalize`}
                                    />
                                  </div>
                                  <div className="adminDetailsBox">
                                    <div className="adminDetailTitle">
                                      Break Time
                                    </div>
                                    <Input
                                      type={`text`}
                                      value={`- ${dialogueData?.breakTime} Hr`}
                                      defaultValue={`--`}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="adminDetailsBox border-top-gray2-1">
                                    <div className="adminDetailTitle">
                                      Total Time
                                    </div>
                                    <Input
                                      type={`text`}
                                      value={`= ${dialogueData?.leaveHours} Hr`}
                                      defaultValue={`--`}
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" col-12">
                            <div className="row">
                              <div className=" col-12">
                                <div className="adminDetails p20">
                                  <div className="adminProfileTitle">
                                    Total Leave Hours
                                  </div>

                                  <div className="adminDetailsBox">
                                    <div className="adminDetailTitle">
                                      Total Days
                                    </div>
                                    <Input
                                      type={`text`}
                                      value={`${dialogueData?.totalDays} Days`}
                                      defaultValue={`--`}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="adminDetailsBox">
                                    <div className="adminDetailTitle">
                                      Total Time
                                    </div>
                                    <Input
                                      type={`text`}
                                      value={`X ${dialogueData?.leaveHours} Hr`}
                                      defaultValue={`--`}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="adminDetailsBox border-top-gray2-1">
                                    <div className="adminDetailTitle">
                                      Total Leave Hours
                                    </div>
                                    <Input
                                      type={`text`}
                                      value={`= ${dialogueData?.totalHours} Hr`}
                                      defaultValue={`--`}
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffLeaveDetails;
