import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  batch: [],
  batchTotal: 0,
  isLoading: false,
  isSkeleton: false,
};
export const batchGet = createAsyncThunk("batch/show", async (payload) => {
  return apiInstance.get(
    `batch/show?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`
  );
});
export const batchAdd = createAsyncThunk("batch/create", async (payload) => {
  return apiInstance.post("batch/create", payload);
});
export const batchUpdate = createAsyncThunk("batch/update", async (payload) => {
  return apiInstance.patch(
    `batch/update?batchId=${payload.batchId}`,
    payload.addBatch
  );
});
export const batchDelete = createAsyncThunk("batch/delete", async (batchId) => {
  return apiInstance.delete(`batch/delete?batchId=${batchId}`);
});

export const batchAction = createAsyncThunk(
  "batch/enableDesableBatch",
  async (payload) => {
    return apiInstance.put(`batch/enableDesableBatch?batchId=${payload}`);
  }
);

const batchSlice = createSlice({
  name: "batchSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // batchGet
    builder.addCase(batchGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(batchGet.fulfilled, (state, action) => {
      state.batch = action.payload.batch;
      state.batchTotal = action.payload.batchTotal;
      state.isSkeleton = false;
    });
    builder.addCase(batchGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // batchCreate
    builder.addCase(batchAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(batchAdd.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.batch.unshift(action.payload.batch);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(batchAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // batchUpdate
    builder.addCase(batchUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(batchUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const batchIdx = state.batch.findIndex(
          (batch) => batch._id === action.payload.batch._id
        );
        if (batchIdx !== -1) {
          state.batch[batchIdx] = {
            ...state.batch[batchIdx],
            ...action.payload.batch,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(batchUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // batchDelete
    builder.addCase(batchDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(batchDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.batch = state.batch.filter(
          (batch) => batch._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(batchDelete.rejected, (state, action) => {
      state.isLoading = false;
    });

    // batchAction
    builder.addCase(batchAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(batchAction.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const batchIdx = state.batch.findIndex(
          (batch) => batch._id === action.payload.batch._id
        );
        if (batchIdx !== -1) {
          state.batch[batchIdx] = {
            ...state.batch[batchIdx],
            ...action.payload.batch,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(batchAction.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default batchSlice.reducer;
