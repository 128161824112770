import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, { Textarea } from "../../../extra/Input";
import Button from "../../../extra/Button";
import { editData, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import { findStaffById } from "../../../../redux/slice/staffLeaveSlice";
import {
  studentLeaveAdd,
  studentLeaveGet,
  studentLeaveUpdate,
} from "../../../../redux/slice/studentLeaveSlice";
import { findStudentByFormNo } from "../../../../redux/slice/studentSlice";
import { setToast } from "../../../extra/toast";

const StudentLeaveAdd = () => {
  const { dialogueData, dialogueText } = useSelector((state) => state.dialogue);
  const { findStudent } = useSelector((state) => state.student);
  const dispatch = useDispatch();

  let studentName = findStudent.studentName || "";
  let student_id = findStudent._id || "";
  let formNo = findStudent.formNo || "";
  useEffect(() => {
    if (dialogueData || dialogueText) {
      editData(dialogueData || dialogueText);
      dispatch(
        findStudentByFormNo(dialogueData?.formNo || dialogueText?.formNo)
      );
    }
    dispatch(findStudentByFormNo(""));
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addLeave = submitData(e);
    if (addLeave) {
      let payload = {
        ...addLeave,
        studentId: student_id,
        formNo,
      };
      try {
        let response;
        if (dialogueData) {
          response = await dispatch(
            studentLeaveUpdate({ payload, studentLeaveId: dialogueData._id })
          ).unwrap();
        } else {
          response = await dispatch(studentLeaveAdd(payload)).unwrap();
          if (response.status) {
            const payload = {
              leaveStatus: 1,
              page: "",
              limit: "",
              search: "",
            };
            dispatch(studentLeaveGet({ ...payload, command: false }));
          }
        }
        if (response.status) {
          dispatch(closeDialog());
        } else {
          setToast(response.message);
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  const handleFindStudent = (datas) => {
    dispatch(findStudentByFormNo(datas));
  };

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-xl-5 col-md-8 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-start formHead">
                <div className="col-8">
                  <h3 className="headTitle m20-bottom">Student Leave Dialog</h3>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                      dispatch(findStaffById(""));
                    }}
                  >
                    <i class="ri-close-circle-line"></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="studentLeaveForm">
                <div className="row align-items-start formBody p40-bottom">
                  <div className="col-lg-6 col-md-6 col-12">
                    <Input
                      type={`text`}
                      id={`formNo`}
                      name={`formNo`}
                      label={`Form No`}
                      errorMessage={`Enter Form No`}
                      onKeyUp={(e) => {
                        handleFindStudent(e.target.value);
                      }}
                      disabled={(dialogueData || dialogueText) && true}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <Input
                      type={`text`}
                      id={`studentName`}
                      name={`studentName`}
                      label={`Student Name`}
                      errorMessage={`Enter Student Name`}
                      disabled={(dialogueData || dialogueText) && true}
                      value={studentName}
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <Input
                      type={`date`}
                      id={`leaveFrom`}
                      name={`leaveFrom`}
                      label={`Leave From`}
                      errorMessage={`Enter Leave From`}
                      minValue={
                        dialogueData?.thisMonthStart
                          ? dialogueData?.thisMonthStart
                          : false
                      }
                      maxValue={
                        dialogueData?.thisMonthEnd
                          ? dialogueData?.thisMonthEnd
                          : false
                      }
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <Input
                      type={`date`}
                      id={`leaveTo`}
                      name={`leaveTo`}
                      label={`Leave To`}
                      errorMessage={`Enter Leave To`}
                      minValue={
                        dialogueData?.thisMonthStart
                          ? dialogueData?.thisMonthStart
                          : false
                      }
                      maxValue={
                        dialogueData?.thisMonthEnd
                          ? dialogueData?.thisMonthEnd
                          : false
                      }
                    />
                  </div>
                  <div className="col-12">
                    <Textarea
                      row={4}
                      type={`date`}
                      id={`description`}
                      name={`description`}
                      label={`Description`}
                      errorMessage={`Enter Description`}
                    />
                  </div>
                </div>
                <div className="row m20-top formFooter">
                  <div className="col-12 text-end m0">
                    <Button
                      className={`bg-gray text-light`}
                      text={`Cancel`}
                      type={`button`}
                      onClick={() => dispatch(closeDialog())}
                    />
                    <Button
                      type={`submit`}
                      className={`bg-second text-light m10-left`}
                      text={`Submit`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentLeaveAdd;
