import React, { useEffect, useRef, useState } from "react";
import Table from "../../../extra/Table";
import Title from "../../../extra/Title";
import Pagination from "../../../extra/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { warning } from "../../../util/Alert";
import Logo from "../../../../assets/images/logo.png";
import { Select } from "../../../extra/Input";
import {
  allStaffSalaryShow,
  staffAllSalaryShow,
  staffSalaryDelete,
  staffSalaryPay,
  staffSalaryReserve,
} from "../../../../redux/slice/staffSalarySlice";
import StaffSalaryDetails from "./StaffSalaryDetails";
import { PrintSalaryReceipt } from "./StaffSalary";
import AddPenaltyBonus from "./AddPenaltyBonus";
import ToggleSwitch from "../../../extra/ToggleSwitch";
const AllReserveStaffSalary = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const { allSalary, salaryYear, salaryMonth, totalAllSalary } = useSelector(
    (state) => state.staffSalary
  );

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const currDate = new Date();
  const liveYears = currDate.getFullYear();
  const [years, setYears] = useState(liveYears);

  const liveMonth = currDate.getMonth() + 1;
  const [monthId, setMonthId] = useState(liveMonth);

  // Pagination BOTH
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(
      allStaffSalaryShow({ year: years, status: 1, monthId, reserve: 1 })
    );
  }, [years, monthId]);

  useEffect(() => {
    dispatch(
      allStaffSalaryShow({ year: years, status: 1, monthId, reserve: 1 })
    );
  }, []);

  const handleYearChanage = async (selectYears) => {
    let responses = await dispatch(
      allStaffSalaryShow({
        year: selectYears,
        status: 1,
        monthId,
        setSalaryData: false,
        reserve: 1,
      })
    );
    if (
      responses?.payload?.status &&
      responses?.payload?.staffSalaryMonth?.length > 0
    ) {
      setYears(selectYears);
      const setMonthData = handleFirstmonth(
        selectYears,
        responses.payload.staffSalaryMonth
      );
      dispatch(
        allStaffSalaryShow({
          year: selectYears,
          status: 1, //false
          monthId: setMonthData,
          reserve: 1, //true
        })
      );
    }
  };

  useEffect(() => {
    handleYearChanage(years);
  }, []);

  const handleFirstmonth = (years, response) => {
    if (response.length > 0) {
      if (years != liveYears) {
        setMonthId(response[0]?.monthId);
        return response[0]?.monthId;
      } else {
        const isInMonth = response.some((item) => item.monthId === liveMonth);
        if (isInMonth) {
          setMonthId(liveMonth);
          return liveMonth;
        } else {
          setMonthId(response[0]?.monthId);
          return response[0]?.monthId;
        }
      }
    }
  };

  useEffect(() => {
    setData(allSalary);
  }, [allSalary]);

  const handlePaySalary = (salaryData) => {
    const data = warning(
      `${salaryData.totalPayingStaffSalary}₹`,
      `Are You Sure Pay?`,
      `Pay`,
      `ri-secure-payment-line`,
      `success`
    );
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(staffSalaryPay(salaryData._id));
        }
      })
      .catch((err) => console.log(err));
  };
  const handleReserveSalary = (salaryData) => {
    const data = warning(
      `${salaryData.totalPayingStaffSalary}₹`,
      `Are You Sure Unreserve?`,
      `Unreserve`,
      `ri-secure-payment-line`,
      `blue`
    );
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(staffSalaryReserve(salaryData._id));
        }
      })
      .catch((err) => console.log(err));
  };
  const handleDeleteSalary = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(staffSalaryDelete(id));
        }
      })
      .catch((err) => console.log(err));
  };

  // Print Salary

  const [prints, setPrints] = useState(0);

  const [datas, setDatas] = useState({});

  const staffSalaryTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Staff Name",
      body: "staffName",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Staff Code",
      body: "staffCode",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Month",
      body: "month",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Year",
      body: "year",
      Cell: ({ row }) => (
        <span>
          {row.monthId}-{row.year}
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Total Work Hours",
      body: "totalWorkingHours",
      aText: "hr",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Total Hours",
      body: "average",
      Cell: ({ row }) => (
        <span>
          <div className="midBox">
            <span>{row?.totalStaffWorkingHours}</span>
            <p className={`${row.isAverage ? "text-success" : "text-danger"}`}>
              ({row.average}%)
            </p>
          </div>
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Leave Status",
      body: "paidLeave",
      Cell: ({ row }) => (
        <span>
          {row.paidLeave ? (
            <div className="midBox">
              <i class="ri-checkbox-circle-line text-success fs-20"></i>
              <p>({row.paidLeaveHours} hr)</p>
            </div>
          ) : (
            <div className="midBox">
              <i class="ri-close-circle-line text-second fs-20"></i>
              <p>(0)</p>
            </div>
          )}
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Per/HR Salary",
      body: "staffPerHourSalary",
      aText: "₹",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Salary",
      body: "payingSalary",
      aText: "₹",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Bounus/Penalty",
      body: "Bounus",
      Cell: ({ row }) => (
        <span
          className={
            row.bonus > 0
              ? `text-success`
              : row.penalty > 0
              ? `text-danger`
              : "0"
          }
        >
          {row.bonus > 0
            ? `+${row.bonus}₹`
            : row.penalty > 0
            ? `-${row.penalty}₹`
            : "0"}
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Total Salary",
      mainClass: `text-success fw-bold`,
      body: "totalPayingStaffSalary",
      aText: "₹",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Pay Salary",
      body: "isPay",
      Cell: ({ row }) => (
        <span>
          {row.isPay ? (
            <ActionButton
              className="bg-success-light text-success border-success-1  m5-right"
              onClick={() => {
                setPrints(1);
                setDatas(row);
              }}
              bIcon={`ri-printer-fill`}
              text={row?.salaryReceiptNo}
            />
          ) : (
            <ActionButton
              className="bg-blue-light text-blue border-blue-1  m5-right"
              onClick={() => handlePaySalary(row)}
              text={`Pay`}
              bIcon={`fa-solid fa-spinner`}
            />
          )}
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-darkGray-light text-darkGray  border-darkGray-1  m5-right"
            onClick={() =>
              dispatch(
                openDialog({ type: "staffSalaryBonusPenalty", data: row })
              )
            }
            icon={`ri-pencil-line`}
          />
          <ActionButton
            className="bg-danger-light text-danger  border-danger-1  m5-right"
            onClick={() => handleDeleteSalary(row._id)}
            icon={`ri-delete-bin-4-line`}
          />
          <ActionButton
            className="bg-blue-light text-blue  border-blue-1  m5-right"
            onClick={() =>
              dispatch(openDialog({ type: "staffSalaryDetails", data: row }))
            }
            icon={`ri-information-line`}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Reserve Salary",
      body: "isReserve",
      Cell: ({ row }) => (
        <span>
          <ToggleSwitch
            onClick={() => handleReserveSalary(row)}
            value={row.isReserve}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
  ];

  return (
    <div>
      <div className="row justify-content-between align-items-center adminPageStart">
        <div className="col-sm-6 col-12">
          <Title name={"Staff Accept Salary"} icon={`ri-bank-card-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m10-right">
            <Searching
              type={`client`}
              data={allSalary}
              setData={setData}
              column={staffSalaryTable}
              className={`w-100`}
            />
          </div>
          <div>
            <Select
              option={salaryYear}
              defaultValue={years}
              onChange={(e) => {
                setYears(e);
                handleYearChanage(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <table className="monthTable">
          <tr>
            {salaryMonth?.map((res) => (
              <td
                onClick={() => setMonthId(res.monthId)}
                className={`${monthId == res.monthId && "active"}`}
              >
                {res.monthName}
              </td>
            ))}
          </tr>
        </table>
      </div>
      <Table
        data={data}
        mapData={staffSalaryTable}
        PerPage={rowsPerPage}
        Page={page}
      />
      <Pagination
        type={"client"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={data?.length}
      />
      {dialogue && dialogueType === "staffSalaryDetails" && (
        <StaffSalaryDetails />
      )}
      {prints == 1 && (
        <PrintSalaryReceipt setPrints={setPrints} datas={datas} />
      )}

      {dialogue && dialogueType === "staffSalaryBonusPenalty" && (
        <AddPenaltyBonus />
      )}
    </div>
  );
};

export default AllReserveStaffSalary;
