import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import Logo from "../../../../assets/images/logo.png";
import { batchGet } from "../../../../redux/slice/batchSlice";

const BatchDetails = () => {
  const dispatch = useDispatch();
  const [accordionData, setAccordionData] = useState([]);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const { batch } = useSelector((state) => state.batch);

  useEffect(() => {
    dispatch(batchGet({ page: 0, limit: 10, search: "" }));
  }, []);
  useEffect(() => {
    setAccordionData(
      batch.map((item) => ({
        id: item.id,
        batchTime: item.batchTime,
        studentName: item.studentName,
      }))
    );
  }, []);

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex(index === openAccordionIndex ? null : index);
  };
  console.log("batch", batch);

  return (
    <div className="dialog">
      <div style={{ width: "40%" }}>
        <div className="row justify-content-center">
          <div className="col-sm-10 col-11">
            <div className="mainDiaogBox position-relative">
              <div className="row justify-content-between align-items-start formHead">
                <div className="col-8"></div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div
                className="logoImg  hw-sm-90 hw-50 position-absolute"
                style={{ top: 10, left: 10 }}
              >
                <img src={Logo} alt="logo" width={`100%`} />
              </div>

              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-xl-12 col-lg-6 text-start mt-4">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      {accordionData.map((item, index) => {
                        return (
                          <div key={item.id}>
                            <p className="accordion-header">
                              <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                                onClick={() => handleAccordionClick(index)}
                              >
                                <p>{item.batchTime}</p>
                              </button>
                              {openAccordionIndex === index && (
                                <div
                                  id="collapseOne"
                                  className="accordion-body accordion-collapse collapse show"
                                  data-bs-parent="#accordionExample"
                                >
                                  {item.studentName.map((name, nameIndex) => (
                                    <div key={nameIndex}>{name}</div>
                                  ))}
                                </div>
                              )}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchDetails;
