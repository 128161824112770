import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import Logo from "../../../../assets/images/logo.png";
import { baseURL } from "../../../util/config";
import Input from "../../../extra/Input";
const StaffSalaryDetails = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();

  return (
    <div className="dialog">
      <div style={{ width: "97%" }}>
        <div className="row justify-content-center">
          <div className="col-xxl-7 col-xl-8 col-lg-10 col-12">
            <div className="mainDiaogBox position-relative  overflow-auto">
              <div className="row justify-content-between align-items-start formHead">
                <div className="col-8"></div>
                <div className="col-4">
                  <div
                    className="closeButton  position-absolute"
                    style={{ top: 10, right: 10 }}
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-circle-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-center justify-content-center">
                <div className="col-12 text-start">
                  <div className="mainLogoPart">
                    <div className="logoImg  width-sm-90 width-50 m-auto m10-bottom">
                      <img src={Logo} alt="logo" width={`100%`} />
                    </div>
                  </div>
                  <div className="staffImage m-auto m10-bottom hw-80 border-second-2 rounded-3 overflow-hidden">
                    <img
                      src={baseURL + dialogueData?.staffId?.image}
                      alt="staff image"
                      width={"100%"}
                      height={"100%"}
                    />
                  </div>
                  <div className="FacultyName text-center text-second">
                    <h5>{dialogueData?.staffName} Details</h5>
                    <p className="fs-14 text-darkGray">
                      ({dialogueData?.position})
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="staffInfo">
                    <div className="row">
                      <div className="col-md-6 col-10 m-auto">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            Employee Information
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              Staff Salary
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.staffSalary} ₹`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              Staff Hours
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.staffHours} ₹`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            {dialogueData?.month} Details
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">Year</div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.year}`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">Month</div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.month}(${dialogueData?.monthId})`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">Days</div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.thisMonthDays} Days`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">Holidays</div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.fixHoliday} Days`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            {dialogueData?.month} Works
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              Working Day
                            </div>
                            <Input
                              type={`text`}
                              value={`${
                                dialogueData?.totalWorkingDays -
                                dialogueData?.manualHoliday
                              } Days`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              Extra Holiday
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.manualHoliday} Days`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              Work Hours
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.totalWorkingHours} Hours(${dialogueData?.totalWorkingDays} Day)`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">Holidays</div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.fixHoliday} Days`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            Staff Hours
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle m15-left">
                              Punch
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.staffPunchHours} Hours (${dialogueData?.average}%)`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox border-bottom-gray2-1">
                            <div className="staffInfoDetailTitle">
                              + Holiday Punch
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.manualHolidayHours} Hours(${dialogueData?.manualHoliday})`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              = Total Hours
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.staffWorkingHours} Hours`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            Staff Leave
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">Leave</div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.leaveHours} Hours`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox ">
                            <div className="staffInfoDetailTitle">
                              Paid Leave
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.paidLeaveHours} Hours`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            Staff Salary
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle m15-left">
                              Staff Salary
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.staffSalary}₹`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox border-bottom-gray2-1">
                            <div className="staffInfoDetailTitle">
                              / Work Hours
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.totalWorkingHours} Hours(${dialogueData?.totalWorkingDays} Day)`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              = Per Hour
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.staffPerHourSalary} ₹`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">
                            Staff Work Hours
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle m15-left">
                              Paid Leave
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.paidLeaveHours} Hours`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox border-bottom-gray2-1">
                            <div className="staffInfoDetailTitle">
                              + Total Hours
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.staffWorkingHours} Hours`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              = Paid Hours
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.totalStaffWorkingHours} Hours`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-10 m-auto">
                        <div className="staffInfoDetails p20-y p15-x">
                          <div className="staffInfoProfileTitle">Salary</div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              Total Salary
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.payingSalary} ₹`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox border-bottom-gray2-1">
                            <div className="staffInfoDetailTitle">
                              Bonus/Penalty
                            </div>
                            <Input
                              type={`text`}
                              value={`${
                                dialogueData.bonus > 0
                                  ? `+${dialogueData.bonus}₹`
                                  : dialogueData.penalty > 0
                                  ? `-${dialogueData.penalty}₹`
                                  : "0"
                              }`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="staffInfoDetailsBox">
                            <div className="staffInfoDetailTitle">
                              Total Paying Salary
                            </div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.totalPayingStaffSalary} ₹`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ----------- */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffSalaryDetails;
