import React, { useEffect, useState } from "react";
import Table from "../../../extra/Table";
import Title from "../../../extra/Title";
import Pagination from "../../../extra/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import {
  inquiryDelete,
  inquiryGet,
  inquiryStatusUpdate,
} from "../../../../redux/slice/inquirySlice";
import { warning } from "../../../util/Alert";
import InquiryDetails from "./InquiryDetails";
import InquiryAdd from "./InquiryAdd";
const PendingInquiry = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { inquiry, inquiryTotal } = useSelector((state) => state.inquiry);

  // Pagination BOTH

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const payload = {
    page,
    limit: rowsPerPage,
    search,
    status: 1,
  };
  // Server Get
  useEffect(() => {
    dispatch(inquiryGet({ ...payload, command: false }));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    dispatch(inquiryGet({ ...payload, command: true }));
  }, []);
  useEffect(() => {
    setData(inquiry);
  }, [inquiry]);

  const handleDelete = (inquiryId) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(inquiryDelete(inquiryId));
        }
      })
      .catch((err) => console.log(err));
  };
  const handleStatus = (inquiryId, status) => {
    dispatch(inquiryStatusUpdate({ inquiryId, status }));
  };
  const inquiryTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Name",
      body: "name",
      sorting: { type: "client" },
      tdClass: "text-capitalize",
    },
    {
      Header: "Id",
      body: "inquiryId",
      Cell: ({ row }) => (
        <span className="text-darkGray fw-600">#{row?.inquiryId}</span>
      ),
      sorting: { type: "client" },
    },
    {
      Header: "Status",
      body: "status",
      Cell: ({ row }) => (
        <button
          className={`${
            row?.status == 1
              ? "bg-blue-light text-blue border-blue-1 p20-x p4-y fs-12"
              : row?.status == 2
              ? "bg-success-light text-success border-success-1 p20-x p4-y fs-12"
              : row?.status == 3 &&
                "bg-danger-light text-danger border-danger-1 p20-x p4-y fs-12"
          } text-light text-capitalize p6-x p3-y fs-10 fw-600 rounded-3`}
        >
          {row?.status == 1
            ? "Pending"
            : row?.status == 2
            ? "Accept"
            : row?.status == 3 && "Reject"}
        </button>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Course",
      body: "course",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Mobile No",
      body: "mobileNo",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Gender",
      body: "gender",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Status",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className={`bg-blue-light text-blue border-blue-1  m5-right ${
              row?.status == 1 && "d-none"
            }`}
            onClick={() => handleStatus(row._id, 1)}
            icon={`fa-regular fa-clock`}
          />
          <ActionButton
            className={`bg-success-light text-success border-success-1  m5-right ${
              (row?.status == 2 || row?.status == 3) && "d-none"
            }`}
            onClick={() => handleStatus(row._id, 2)}
            icon={`ri-check-line`}
          />
          <ActionButton
            className={`bg-danger-light text-danger border-danger-1  m5-right ${
              (row?.status == 2 || row?.status == 3) && "d-none"
            }`}
            onClick={() => handleStatus(row._id, 3)}
            icon={`ri-close-fill`}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-darkGray-light text-darkGray  border-darkGray-1  m5-right"
            onClick={() => dispatch(openDialog({ type: "inquiry", data: row }))}
            icon={`ri-pencil-fill`}
          />
          <ActionButton
            className="bg-second-light text-second border-second-1  m5-right"
            onClick={() => handleDelete(row._id)}
            icon={`ri-delete-bin-line`}
          />
          <ActionButton
            className="bg-warning-light text-warning border-warning-1 m5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "inquiryDetails",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
            icon={`ri-information-line`}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart">
        <div className="col-sm-6 col-12">
          <Title name={"Pending Inquiry"} icon={`ri-user-search-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m10-right">
            <Searching
              type={`server`}
              data={data}
              setData={setData}
              column={inquiryTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-darkGray-2 m20-right`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "inquiry" }));
              }}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={inquiryTable}
        serverPerPage={rowsPerPage}
        Page={page}
      />
      <Pagination
        type={"server"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={inquiryTotal}
      />
      {dialogue && dialogueType === "inquiry" && <InquiryAdd />}
      {dialogue && dialogueType === "inquiryDetails" && <InquiryDetails />}
    </>
  );
};

export default PendingInquiry;
