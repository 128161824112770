import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  allSalary: [],
  salary: [],
  staffSalary: {},
  salaryYear: [],
  salaryMonth: [],
  totalAllSalary: 0,
  salaryReceipts: [],
  salaryReceiptsCount: 0,
  isLoading: false,
  isSkeleton: false,
};
export const staffSalaryGet = createAsyncThunk(
  "staff/staffSalaryInfo",
  async (payload) => {
    return apiInstance.get(`staff/staffSalaryInfo?staffPunchId=${payload}`);
  }
);
export const staffAllSalaryShow = createAsyncThunk(
  "staffSalary/showStaffSalary",
  async (payload) => {
    return apiInstance.get(
      `staffSalary/showStaffSalary?staffId=${payload.staffId}&year=${payload.year}`
    );
  }
);
export const allStaffSalaryShow = createAsyncThunk(
  "staffSalary/showAllStaffSalary",
  async (payload) => {
    return apiInstance.get(
      `staffSalary/showAllStaffSalary?year=${payload.year}&status=${payload.status}&monthId=${payload.monthId}&reserve=${payload.reserve}`
    );
  }
);
export const addSalaryPenaltyBonus = createAsyncThunk(
  "staffSalary/addPenaltyBonus",
  async (payload) => {
    return apiInstance.patch(
      `staffSalary/addPenaltyBonus?staffSalaryId=${payload.staffSalaryId}`,
      payload
    );
  }
);
export const staffSalaryDelete = createAsyncThunk(
  "staffSalary/deleteSalary",
  async (payload) => {
    return apiInstance.delete(
      `staffSalary/deleteSalary?staffSalaryId=${payload}`
    );
  }
);
export const staffSalaryPay = createAsyncThunk(
  "staffSalary/paySalary",
  async (payload) => {
    return apiInstance.put(`staffSalary/paySalary?staffSalaryId=${payload}`);
  }
);
export const staffSalaryReserve = createAsyncThunk(
  "staffSalary/reserveSalary",
  async (payload) => {
    return apiInstance.put(
      `staffSalary/reserveSalary?staffSalaryId=${payload}`
    );
  }
);
export const staffSalaryReceipts = createAsyncThunk(
  "staffSalary/showSalaryReceipts",
  async (payload) => {
    return apiInstance.get(
      `staffSalary/showSalaryReceipts?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`
    );
  }
);

const staffSalarySlice = createSlice({
  name: "staffSalarySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // staffSalaryGet
    builder.addCase(staffSalaryGet.pending, (state, action) => {
      state.isSkeleton = false;
    });
    builder.addCase(staffSalaryGet.fulfilled, (state, action) => {
      state.staffSalary = action.payload.staffSalaryInfo;
      state.isSkeleton = false;
    });
    builder.addCase(staffSalaryGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // staffAllSalaryShow
    builder.addCase(staffAllSalaryShow.pending, (state, action) => {
      state.isSkeleton = false;
    });
    builder.addCase(staffAllSalaryShow.fulfilled, (state, action) => {
      state.salary = action.payload.staffSalary;
      state.isSkeleton = false;
    });
    builder.addCase(staffAllSalaryShow.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // allStaffSalaryShow
    builder.addCase(allStaffSalaryShow.pending, (state, action) => {
      state.isSkeleton = false;
    });
    builder.addCase(allStaffSalaryShow.fulfilled, (state, action) => {
      if (action.meta.arg.setSalaryData !== false) {
        state.allSalary = action.payload.staffSalary;
      }
      state.salaryYear = action.payload.staffSalaryYear;
      state.salaryMonth = action.payload.staffSalaryMonth;
      state.totalAllSalary = action.payload.staffSalaryTotal;
      state.isSkeleton = false;
    });
    builder.addCase(allStaffSalaryShow.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // addSalaryPenaltyBonus
    builder.addCase(addSalaryPenaltyBonus.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addSalaryPenaltyBonus.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const salaryIdx = state.allSalary.findIndex(
          (salary) => salary._id === action.payload.staffSalary._id
        );
        if (salaryIdx !== -1) {
          state.allSalary[salaryIdx] = {
            ...state.allSalary[salaryIdx],
            ...action.payload.staffSalary,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(addSalaryPenaltyBonus.rejected, (state, action) => {
      state.isLoading = false;
    });

    // staffSalaryDelete
    builder.addCase(staffSalaryDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffSalaryDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.allSalary = state.allSalary.filter(
          (salary) => salary._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffSalaryDelete.rejected, (state, action) => {
      state.isLoading = false;
    });

    // staffSalaryPay
    builder.addCase(staffSalaryPay.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffSalaryPay.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const salaryIdx = state.allSalary.findIndex(
          (salary) => salary._id === action.payload.staffSalary._id
        );
        if (salaryIdx !== -1) {
          state.allSalary[salaryIdx] = {
            ...state.allSalary[salaryIdx],
            ...action.payload.staffSalary,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffSalaryPay.rejected, (state, action) => {
      state.isLoading = false;
    });

    // staffSalaryReserve
    builder.addCase(staffSalaryReserve.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffSalaryReserve.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const salaryIdx = state.allSalary.findIndex(
          (salary) => salary._id === action.payload.staffSalary._id
        );
        if (salaryIdx !== -1) {
          state.allSalary[salaryIdx] = {
            ...state.allSalary[salaryIdx],
            ...action.payload.staffSalary,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffSalaryReserve.rejected, (state, action) => {
      state.isLoading = false;
    });

    // staffSalaryReceipts
    builder.addCase(staffSalaryReceipts.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(staffSalaryReceipts.fulfilled, (state, action) => {
      state.salaryReceipts = action.payload.salaryReceipts;
      state.salaryReceiptsCount = action.payload.salaryReceiptsTotal;
      state.isSkeleton = false;
    });
    builder.addCase(staffSalaryReceipts.rejected, (state, action) => {
      state.isSkeleton = false;
    });
  },
});
export default staffSalarySlice.reducer;
